<template>
  <Page ref="pageRef" showType="contentFlow" :show="showPage" :isScale="isScale">
    <div class="bar-chart-wrap" :class="{ scale: isScale }">
      <ReadBarChart ref="barChart"></ReadBarChart>
    </div>
    <div class="block" :class="{ show: blockShow, scale: isScale }">
      <div class="block-shade"></div>
      <div class="block-title"><span>10万+趋势内容</span></div>
      <div class="fixed-trend">
        <div class="trend">
          <img class="trend-logo" v-if="topData.account" :src="topData.account.avatar" alt="">
          <div class="trend-title">
            {{ topData.title }}
          </div>
        </div>
        <div class="chart">
          <TrendChart ref="trendChart"></TrendChart>
        </div>
      </div>
      <div class="block-content">
         <scroll-list
          :list="trendArticleList"
          :item-size="96"
          direction="vertical"
          :buffer="500"
          :prerender="500"
          :speed="5"
          scrollType="step"
          ref="trendScrollList"
          class="trend-scroll-list"
          @scrollEnd="trendArticleScrollEnd"
          @stepScroll="trendStepScroll"
        >
          <template v-slot="{ item, index }">
            <div class="trend trend-item" :data-info="JSON.stringify(item)">
              <img class="trend-logo" :src="item.account.avatar" alt="">
              <div class="trend-title">
                {{ item.title }}
              </div>
            </div>
          </template>
        </scroll-list>
      </div>
    </div>
    <div class="block block-2" :class="{ show: blockShow, scale: isScale }">
      <div class="block-title"><span>年度爆款排行</span></div>
      <div class="block-content">
        <scroll-list
          :list="hotArticleList"
          :item-size="132"
          direction="vertical"
          :buffer="500"
          :prerender="500"
          :speed="0.3"
          ref="hotScrollList"
          class="hot-scroll-list"
          @scrollEnd="hotArticleScrollEnd"
        >
          <template v-slot="{ item, index }">
            <div class="hot" :class="{ new: index == 0 }">
              <div class="hot-rank" :class="{ top: item.rank < 4 }">{{ item.rank }}</div>
              <div class="hot-title">
                {{ item.title }}
              </div>
              <div class="hot-bottom">
                阅读量 <span>{{ item.pv.total | numTrans }}</span>
              </div>
            </div>
          </template>
        </scroll-list>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "./components/page"
import ScrollList from "@/components/scrollList.vue"
import DynamicScrollList from "@/components/dynamicScrollList.vue"
import ReadBarChart from "./components/readBarChart.vue"
import TrendChart from "./components/trendChart.vue"
import utils from '@/assets/javascripts/utils'
import _ from 'lodash'

export default {
  components: { Page, ScrollList, DynamicScrollList, ReadBarChart, TrendChart },
  props: {
    isScale: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPage: false,
      cur: 0,
      list: [],
      topData: {},
      totalPageCount: 10, // 限制数据总页数
      trendArticleList: [],
      trendArticlePageParam: {
        page: 1,
        perPage: 10,
      },
      trendArticlePageData: {},
      trendKey: 0,
      hotArticleList: [],
      hotArticlePageParam: {
        page: 1,
        perPage: 10,
      },
      hotArticlePageData: {},
      hotKey: 0,
      blockShow: false,
      lastFetchTime: Date.now(), // 上次更新时间
    }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    async initPage() {
      this.getStatist()
      this.getHotArticle(true)
      this.getTrendArticle(true)

      // 轮询检查是否更新数据
      setInterval(() => {
        this.refreshData()
      }, 10*1000);

      await utils.sleep(500)
      this.getRank()
    },
    // 流量统计数据
    async getStatist() {
      const res = await this.$api.content.getStatist()
      const { today, todayOutside, todayInside,trend } = res
      this.$nextTick(async () => {
        this.$refs.pageRef.setData([
            {
              title: "今日流量",
              value: today,
              // percent: Math.abs(utils.floatMul(trend, 100)),
              percent: Math.abs(_.multiply(trend, 100)),
              percentUnit: '%',
            },
            {
              title: "站内流量",
              value: todayInside,
            },
            {
              title: "站外流量",
              value: todayOutside,
            },
        ])   
      })
    },
    // 获取排名
    async getRank() {
      const res = await this.$api.content.getRank()
      const { items } = res
    
      this.$refs.barChart.initChart('flow', items)
    },
    // 获取趋势内容
    async getTrendArticle(init = false) {
      const { currentPage, pageCount } = this.trendArticlePageData
      const _pageCount = pageCount>this.totalPageCount? this.totalPageCount:pageCount
      if (!init) {
        if (currentPage === _pageCount) {
          // 到底了，重新请求第一页文章
          this.trendArticlePageParam.page = 1
        } else {
          this.trendArticlePageParam.page++
        }
      } 
      const res = await this.$api.content.getTrendArticlePage({
        ...this.trendArticlePageParam
      })
      let { items, page } = res
      this.trendArticlePageData = page

      items = items.map((item) => Object.assign(item, { key: this.trendKey++ }))
      this.trendArticleList = this.trendArticleList.concat(items)

      this.$nextTick(async () => {
        if (init) {
          this.topData = this.trendArticleList[0]
          this.trendArticleList.splice(0, 1)
          this.$refs.trendChart.initChart('flowTrend', this.topData.trend)
          this.$refs.trendScrollList.startScroll()
          this.blockShow = true
          this.$emit('show', true)
          await utils.sleep(200)
          this.showPage = true
        }
      })
    },
    trendArticleScrollEnd() {
      this.getTrendArticle()
    },
    trendStepScroll() {
      const wrapEl = this.$refs.trendScrollList.$el
      const wrapTop = wrapEl.getBoundingClientRect().top 
      let els = Array.from(document.querySelectorAll('.trend-item'))
      els = els.filter(i => {
        const top = i.getBoundingClientRect().top 
        return top < wrapTop
      })
  
      if (els) {
        const currentData = JSON.parse(els[els.length-1].getAttribute('data-info'))
        this.topData = currentData
        this.$refs.trendChart.initChart('flowTrend', currentData.trend)
      }
    },

    // 获取年度爆款排行
    async getHotArticle(init = false) {
      const { currentPage, pageCount } = this.hotArticlePageData
      const _pageCount = pageCount>this.totalPageCount? this.totalPageCount:pageCount
      if (!init) {
        if (currentPage === _pageCount) {
          // 到底了，重新请求第一页文章
          this.hotArticlePageParam.page = 1
        } else {
          this.hotArticlePageParam.page++
        }
      } 
      const res = await this.$api.content.getHotArticlePage({
        ...this.hotArticlePageParam
      })
      let { items, page } = res
      this.hotArticlePageData = page

      items = items.map((item) => Object.assign(item, { key: this.hotKey++ }))
      this.hotArticleList = this.hotArticleList.concat(items)

      this.$nextTick(async () => {
        if (init) {
          this.$refs.hotScrollList.startScroll()
        }
      })
    },
    hotArticleScrollEnd() {
      this.getHotArticle()
    },

    // 轮询更新, 每3分钟更新一次
    refreshData() {
      const curTime = Date.now()
      if (curTime - this.lastFetchTime > 1000*60*3) {
        this.getStatist()
        this.getRank()
        this.lastFetchTime = curTime
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.bar-chart-wrap {
  position: absolute;
  top: 224px;
  left: 50px;
  right: 860px;
  bottom: 50px;
  &.scale {
    right: 620px;
    transform: scaleX(.88);
    transform-origin: left;
  }
}

.block {
  position: absolute;
  top: 120px;
  bottom: 0;
  right: 454px;
  width: 364px;
  // height: 854px;
  opacity: 0;
  border-radius: 12px 12px 12px 12px;
  box-shadow: 0 18px 32px 0 rgba($color: #d6d1e8, $alpha: 0.55);
  &.scale {
    transform: scaleX(.88);
    transform-origin: right;
    right: 400px;
    &.block-2 {
      right: 50px;
    }
  }
  &.show {
    opacity: 1;
    transition: all .3s ease;
  }
  &-2 {
    right: 50px;
    box-shadow: none;
    .block-title {
      &>span {
        box-shadow: 0 18px 32px 0 rgba($color: #d6d1e8, $alpha: 0.55);
      }
    }
    .block-content {
      padding-top: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }
  &-title {
    position: relative;
    height: 64px;
    &>span {
      position: absolute;
      top: 0px;
      left: 0;
      width: 364px;
      height: 130px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-bottom: 66px;
      color: $font-color;
      @include font-oppo-b;
      font-size: 18px;
      font-weight: bold;
      border-radius: 12px 12px 0 0;
      background: linear-gradient(360deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    }
  }
  &-content {
    position: relative;
    padding-top: 293px;
    height: calc(100% - 64px);
    background: #fff;
    border-radius: 12px 12px 0px 0px;
    box-shadow: 0px -10px 20px 0px rgba(220,220,220,0.25);
  }

  .fixed-trend {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    padding-bottom: 20px;
    background: #fff;
    border-radius: 12px 12px 0px 0px;
    z-index: 10;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      height: 1px;
      background: rgba(37,37,37,0.06);
    }
    .trend {
      width: auto;
      height: 97px;
      padding-bottom: 18px;
      border-bottom: none;
      &:after {
        display: none;
      }
    }
    .chart {
      position: relative;
      height: 182px;
    }
  }

  .trend {
    position: relative;
    margin: 0 20px;
    padding: 24px 0px 24px 42px;
    width: 100%;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 0px;
      background: rgba(37,37,37,0.06);
      height: 1px;
    }
    &-logo {
      position: absolute;
      top: 24px;
      left: 0px;
      width: 32px;
      height: 32px;
      border-radius: 6px;
    }
    &-title {
      position: relative;
      font-size: 18px;
      line-height: 24px;
      color: $font-color;
      font-weight: 500;
      @include font-oppo-m;
      @include textOverflow;
    }
  }

  .hot {
    position: relative;
    margin-bottom: 8px;
    width: 100%;
    height: 124px; 
    padding: 24px 20px 20px 62px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px -10px 20px 0px rgba(220,220,220,0.25);
    &-rank {
      position: absolute;
      top: 24px;
      left: 20px;
      width: 32px;
      height: 32px;
      background: linear-gradient(32deg, #252525 0%, #777777 100%);
      border-radius: 6px 6px 6px 6px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      @include font-din;
      &.top {
        background: linear-gradient(221deg, #A145D6 0%, #3847F8 100%);
      }
    }
    &-title {
      margin-bottom: 12px;
      height: 48px;
      font-size: 18px;
      @include font-oppo-m;
      font-weight: 500;
      color: $font-color;
      line-height: 24px;
      @include textOverflow;
    }
    &-bottom {
      color: $font-color-2;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      @include font-oppo-m;
      span {
        margin-left: 4px;
        color: $font-color-high;
        @include font-din;
        font-weight: bold;
      }
    }
  }
}

</style>

<style lang="scss">
.vue-recycle-scroller.direction-horizontal .vue-recycle-scroller__item-wrapper {
  // margin-left: 660px;
}
</style>