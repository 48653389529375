<template>
  <div class="index-page"> 
    <ContentArticle :isScale="isScale" v-if="pageLoadList.includes('contentArticle')" ref="contentArticle" @show="pageShow('contentArticle')"></ContentArticle>
    <ContentFlow :isScale="isScale" v-if="pageLoadList.includes('contentFlow')" ref="contentFlow" @show="pageShow('contentFlow')"></ContentFlow>
    <ContractRegion :isScale="isScale" v-if="pageLoadList.includes('contractRegion')" ref="contractRegion" @show="pageShow('contractRegion')"></ContractRegion>
    <ContractPlate :isScale="isScale" v-if="pageLoadList.includes('contractPlate')" ref="contractPlate" @show="pageShow('contractPlate')"></ContractPlate>

    <!-- 经营大屏tab -->
    <!-- <div class="tab-wrap contract-tab" :class="{show: activeTab.startsWith('contract'), scale: isScale, 'origin-left': activeTab == 'contractRegion'}">
      <div class="tab" :class="{ bottom: activeTab == 'contractPlate' }">
        <div class="tab-item" :class="{ active: activeTab === item.name}" v-for="(item, index) in tabsData.contract" :key="index">{{ item.text }}</div>
      </div>
    </div> -->
    <!-- 内容大屏tab -->
    <!-- <div class="tab-wrap content-tab" :class="{show: activeTab.startsWith('content'), scale: isScale }">
      <div class="tab">
        <div class="tab-item" :class="{ active: activeTab === item.name}" v-for="(item, index) in tabsData.content" :key="index">{{ item.text }}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import utils from '@/assets/javascripts/utils'
import ContentArticle from '../content/article.vue'
import ContentFlow from '../content/flow.vue'
import ContractRegion from '../contract/region.vue'
import ContractPlate from '../contract/plate.vue'

export default {
  components: { ContentArticle, ContentFlow, ContractRegion, ContractPlate },
  data() {
    return {
      tabs: [],
      tabsData: {
        content: [
          {
            name: 'contentArticle',
            text: '实时更新情况（篇）', 
          },
          {
            name: 'contentFlow',
            text: '实时流量情况（PV）',
          }
        ],
        contract: [
          {
            name: 'contractRegion',
            text:'按地区'          
          }, 
          {
            name: 'contractPlate',
            text: '按板块'
          }
        ],
      },
      activeTab: '',
      isScale: false,
      pageList: ['contentArticle', 'contentFlow', 'contractRegion', 'contractPlate'],
      pageLoadList: [],
      prevPage: '',
      currentPage: '',
      changeSeconds: 180, // 自动切换页面时间，单位秒钟
    }
  },
  watch: {
    currentPage(page) {
      // if (page.startsWith('content')) {
      //   this.tabs = this.tabsData.content
      // } else if (page.startsWith('contract')) {
      //   this.tabs = this.tabsData.contract
      // }
      
      this.activeTab = page
    }
  },
  mounted() {
    if (this.$route.query.t == 'scale') this.isScale = true
    this.changeSeconds = this.$route.query.second?this.$route.query.second:180
    this.setPage()
  },
  methods: {
    async pageShow(name) {
      this.$refs[this.prevPage] && (this.$refs[this.prevPage].showPage = false)
      this.currentPage = name
      await utils.sleep(3000)
      this.pageLoadList = [name]
      this.prevPage = name
    },
    setPage() {
      const { page } = this.$route.query
      let curPage = this.pageList[0]
      if (page && this.pageList.includes(page)) {
        curPage = page
      } else {
        this.switchPage()
      }
      this.pageLoadList.push(curPage)
    },
    switchPage() {
      // 3分钟切换
      setInterval(() => {
        let pageIndex = this.pageList.findIndex(i => i == this.currentPage)
        pageIndex = pageIndex == this.pageList.length - 1? 0:pageIndex+1
        const nextPage = this.pageList[pageIndex]
        this.pageLoadList.push(nextPage)
      }, 1000*this.changeSeconds)
    },
  }
}
</script>

<style lang="scss" scoped>
.index-page {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background: url('~@/assets/images/bg.png') no-repeat;
  background-size: cover;
  background-position: left center;
  
  .tab-wrap {
    opacity: 0;
    transition: .5s ease;
    &.show {
      opacity: 1;
    }
    &.scale {
      transform: scaleX(.88);
      transform-origin: right;
      &.origin-left {
        // transform-origin: left;
      }
    }
    &.contract-tab {
      .tab {
        left: calc(100vw - 245px - 50px);
        right: unset;
        width: 245px;
        transform: translateY(30px);
        transition: 1s .3s ease-in-out, width 0s;
        &.bottom {
          // top: calc(100vh - 292px);
          transform: translateY(calc(100vh - 402px));
        }
      }
    }
  }

  .tab {
    position: fixed;
    top: 120px;
    right: 50px;
    width: 430px;
    height: 54px;
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.8);
    border-radius: 11px 11px 11px 11px;
    box-shadow: 0 18px 32px 0 rgba($color: #d6d1e8, $alpha: 0.55);
    // opacity: 0;
    // transition: opacity 1s .3s ease-in-out;
    // transition: opacity 1s .3s ease-in-out, width 0s;
    z-index: 99;
    &.show {
      opacity: 1;
    }

    &-item {
      position: relative;
      flex: 1;
      // padding-left: 49px;
      padding-left: 56px;
      height: 18px;
      font-size: 14px;
      color: $font-color-2;
      @include font-oppo-m;
      white-space: nowrap;
      &:first-of-type {
        border-right: 1px solid rgba(37,37,37,0.25);
      } 
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        // left: 26px;
        left: 30px;
        width: 14px;
        height: 14px;
        background-color: $font-color-2;
        border-radius: 4px;
      }
      &.active {
        color: $font-color;
        &:after {
          background-color: $font-color;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>