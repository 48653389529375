<template>
  <div class="trend-chart">
    <div :id="chartId"></div>
    <div class="chart-point">
      <div class="point" v-for="(item, index) in pointPosition" :class="item.cls" :key="item.id" :style="pointStyle(index)"></div>
    </div>
    <div class="chart-bottom-line" :style="bottomLineStyle()"></div>
    <div class="chart-line" :class="{show: showLine}">
      <div class="line-item" v-for="(item, index) in list" :key="index" :class="{show: showList.includes(index)}" :style="lineStyle(index, item)">
        <div class="item-label">
          {{ item.value | numTrans(true) }}
        </div>
      </div>
    </div>
    <div class="markarea" v-if="pointPosition.length">
      {{ markAreaVal | numTrans }}
      <div class="markarea-text">阅读数</div>
      <div class="markarea-bottom" :style="areaBottomStyle()"></div>
    </div>
  </div>
</template>

<script>
import utils from '@/assets/javascripts/utils'

export default {
  name: "ReadBarChart",
  data() {
    return {
      chartId: "",
      markAreaVal: 0,
      $chart: null,
      list: [],
      pointPosition: [],
      showLine: false,
      showList: [],
      utils,
    }
  },
  methods: {
    initChart(chartId, list) {
      this.list = list
      const xAxisList = list.map((i) => i.date)
      xAxisList[xAxisList.length - 1] = 'Today'
      const dataList = list.map((i) => i.value)
      const lastIndex = list.length-1
      this.markAreaVal = dataList[lastIndex]

      this.showList = []
      list.forEach((item, index) => {        
        if(index == 1) {
          this.showList.push(index)
        }
      })

      const option = {
        grid: {
          top: '12%',
          left: "5%",
          right: "13%",
          bottom: "0px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            formatter:  function (value, index) {
              if (value == 'Today') return value
              return utils.dateFormat(new Date(value*1000), 'mm.dd');
            },
           
            fontSize: 11,
            fontStyle: "bold",
            color: function (param, index) {
              if (index == lastIndex) {
                return '#333333'
              }
              return "#BDBDBD"
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          labelLine: {
            show: false,
          },

          // prettier-ignore
          data: xAxisList,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // formatter: function(value) {
            //   // 当值为0时，返回空字符串，不显示
            //   return value === 0 ? '' : value;
            // },
            formatter: "{value}",
            fontSize: 8,
            // width: 10,
            color: "#BDBDBD", 
          },
          axisPointer: {
            snap: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 2,
              color: "rgba(242, 242, 242, 1)",
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "Electricity",
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                shadowBlur: 20,
                shadowOffsetY: 9,
                shadowColor: 'rgb(162, 69, 212, .5)',
                // color: "#A245D4", //改变折线点的颜色
                color: 'transparent',
                lineStyle: {
                  width: 2,
                  color: "#A245D4", //改变折线颜色
                },
              },
            },
            data: dataList,
          },
        ],
      }

      this.chartId = chartId
      this.$nextTick(() => {
        if (!this.$chart) {
          this.$chart = this.$echarts.init(document.getElementById(this.chartId))
        }
        this.$chart.setOption(option)
        this.initPointPosition()
      })
    },
    async initPointPosition() {
      let model1 = this.$chart.getModel().getSeriesByIndex(0).getData()._layout;
      let points = model1.points

      this.pointPosition = []
      points.forEach((point1, index) => {
        if (index % 2 == 0) {
          // 奇数项为x坐标，偶数项为y坐标
          this.pointPosition.push({ x: point1, id: Math.random()*Date.now() })
        } else {
          this.pointPosition[this.pointPosition.length - 1].y = point1
        }
      })
      this.showLine = true
    },
    bottomLineStyle() {
      try {
        const { x } = this.pointPosition[0]
        return {
          left: x + 'px',
        }
      } catch(err) {
        return {}
      }
    },
    lineStyle(index) {
      try {
        const { x } = this.pointPosition[index]
        return {
          top: 0 + 'px',
          left: x + 'px',
        }
      } catch(err) {
        return {}
      }
    },
    pointStyle(index) {
      const { x, y } = this.pointPosition[index]
      let top = y - 4
      let left = x - 3

      return {
        top: top + "px",
        left: left + "px",
      }
    },
    areaBottomStyle() {
      const { x, y } = this.pointPosition[this.pointPosition.length-1]
      let top = y + 10

      return {
        top: top + 'px'
      }
    },
  },
  destroyed() {
    this.$chart.dispose()
    this.$chart = null
    this.chartId = null
    this.markAreaVal = 0
  }
}
</script>

<style lang="scss" scoped>
.trend-chart {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  & > div {
    position: relative;
    z-index: 10;
    height: 100%;
  }
  .markarea {
    position: absolute;
    top: -10px;
    bottom: -10px;
    right: 0px;
    height: auto;
    transform: translateX(-38%);
    min-width: 54px;
    padding: 6px 5px;
    border-radius: 5px 5px 5px 5px;
    opacity: 0.8;
    color: #fff;
    font-size: 15px;
    line-height: 12px;
    text-align: center;
    background: #F992AE;
    @include font-din;
    font-weight: bold;
    z-index: -1;
    &-text {
      font-size: 12px;
      transform: scale(.5);
    }
    &-bottom {
      position: absolute;
      top: 40px;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 5px;
      background: linear-gradient( 180deg, #F77C9E, #F48EB8);
    }
  }

  .chart-point {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 10;
    .point {
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 8px;
      // background: linear-gradient(219deg, rgba(228, 139, 222, 1), rgba(249, 116, 145, 1));
      border-radius: 50%;
      z-index: 10;
      transition: .3s ease;
      background-color: #A245D4;
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 2px;
        left: 2px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #fff;
      }
    }
  }

  .chart-bottom-line {
    position: absolute;
    bottom: 18px;
    left: 0;
    right: 75px;
    height: 1px;
    background: #F2F2F2;
  }

  .chart-line {
    position: absolute;
    top: 10px;
    left: 0;
    bottom: 18px;
    height: auto;
    transition: .5s ease;
    opacity: 0;
    z-index: -1;
    &.show {
      opacity: 1;
    }
    .line-item {
      position: absolute;
      width: 1px;
      height: 100%;
      border: 1px dashed #C4D3F1; //#F97491; //#A245D4;
      transition: .3s ease;
      opacity: 0;
      &.show {
        opacity: 1;
      }
      .item-label {
        position: absolute;
        top: -16px;
        left: 0;
        transform: translateX(-50%);
        font-size: 11px;
        color: #A245D4;
        @include font-din;
      }
    }
  }
}
</style>
