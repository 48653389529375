<template>
  <div class="read-bar-chart">
    <div :id="chartId"></div>
    <div class="label" :class="{ show: labelShow }">
      <div class="label-item" v-for="item in list" :key="item.name" :style="{
        top:item.y+'px',
        height:item.height+'px', 
        width:item.width+'px',
        fontSize:item.fontSize+'px'
      }">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
import utils from '@/assets/javascripts/utils'

export default {
  name: "ReadBarChart",
  data() {
    return {
      chartId: "",
      $chart: null,
      list: [],
      labelShow: false
    }
  },
  mounted() {},
  methods: {
    initChart(chartId, list) {
      this.list = list
      const yAxisList = list.map(i => i.name)
      const dataList = list.map(i => list.length - i.rank)
      // const dataList = list.map(i => i.rank)

      const maxData = list.reduce(function(prev, cur, index) {
        if (cur.articlePv > (prev.articlePv || 0)) {
          prev = cur
          prev.index = index
        }
        return prev
      }, {})
      const maxIndex = maxData.index

      const option = {
        tooltip: {
          show: false,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: false,
        },
        grid: {
          left: "0%",
          right: "10%",
          bottom: "0px",
          containLabel: false,
        },
        xAxis: {
          type: "value",
          axisLine: false,
          splitLine: false,
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          show: false,
          axisLine: false,
          type: "category",
          splitLine: false,
          data: yAxisList,
        },
        series: [
          {
            type: "bar",
            showBackground: false,
            barWidth: 'auto',
            barCategoryGap: '40%', // 间距
            // barMinWidth: 28,
            // barMaxWidth: 28,
            // barMaxHeight: 1000,
            barMinHeight: 150,
            animationDuration: 1500,
            label: {
              show: true,
              position: "right",
              formatter: function(params) {
                return `阅读值：${list[params.dataIndex].articlePv}`
              },
              fontWeight: "bold",
              color: '#000'
            },
            itemStyle: {
              normal: {
                //显示
                color: ({ dataIndex }) => {
                  const colorList = [
                    ['#F97491', '#E48BDE'],
                    ["#8831E2", "#FF7635"]
                  ]
                  
                  const colorItem = dataIndex === maxIndex? colorList[1]:colorList[0]
                  return new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    { offset: 0, color: colorItem[0] },
                    { offset: 1, color: colorItem[1] },
                  ])
                },
                borderRadius: 20,
              },
            },
            data: dataList,
          },
          // {
          //   type: "bar",
          //   emphasis: {
          //     disabled: true,
          //   },
          //   barGap: "-100%",
          //   zlevel: 1,
          //   label: {
          //     show: true,
          //     // position: [12, 8],
          //     align: 'right',
          //     // lineHeight: 50,
          //     // verticalAlign:'middle',
          //     formatter: "{b}",
          //     fontWeight: "bold",
          //     // width: 2000,
          //     color: "#fff",
          //   },
          //   animationDuration: 1500,
          //   itemStyle: {
          //     normal: {
          //       //显示
          //       color: ({ dataIndex }) => {
          //         const colorList = [
          //           ['#F97491', '#E48BDE'],
          //           ["#8831E2", "#FF7635"]
          //         ]
                  
          //         const colorItem = dataIndex === maxIndex? colorList[1]:colorList[0]
          //         return new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
          //           { offset: 0, color: colorItem[0] },
          //           { offset: 1, color: colorItem[1] },
          //         ])
          //       },
          //       borderRadius: 20,
          //     },
          //   },
          //   barWidth: 'auto',
          //   // barCategoryGap: '50%', // 间距
          //   // barCategoryGap: 30,
          //   // barMinWidth: 28,
          //   // barMaxWidth: 28,
          //   // barMaxHeight: 1000,
          //   // barMinHeight: 150,
          //   data: dataList,
          // },
        ],
      }

      this.chartId = chartId
      this.$nextTick(() => {
        if (!this.$chart) {
          this.$chart = this.$echarts.init(document.getElementById(this.chartId))
        }
        this.$chart.setOption(option)
        setTimeout(() => {
          this.setLabel()
        }, 10)
      })
    },
    async setLabel() {
      let model = this.$chart.getModel().getSeriesByIndex(0).getData()._itemLayouts;

      this.list = this.list.map((i, index) => {
        let height = model[index].height
        let fontSize = height/4 
        fontSize = fontSize < 16? 16:fontSize
        this.$chart.setOption({
          series: [{
            barMinHeight: fontSize*10+40,
            label: {
              fontSize: fontSize
            },
            itemStyle: {
              normal: {
                borderRadius: height*0.71
              }
            }
          }]
        })
        return Object.assign(i, model[index], {
          fontSize
        })
      })
      await utils.sleep(1000)
      this.labelShow = true
    },
    setData() {
      // this.$chart.setOption(option)
    }
  },
  destroyed() {
    this.$chart.dispose()
    this.$chart = null
    this.chartId = null
  }
}
</script>

<style lang="scss" scoped>
.read-bar-chart {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // left: 38px;
  // bottom: 50px;
  // width: 1102px;
  // height: 780px;
  padding-bottom: 60px;
  background: url("~@/assets/images/content/icon_coordinate@2x.png") no-repeat;
  // background-color: #f00;
  background-position: left bottom;
  background-size: 67.5px 54px;
  &>div {
    height: 100%;
  }
  .label {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .3s ease;
    &-item {
      position: absolute;
      display: flex;
      // font-size: 12px;
      align-items: center;
      padding-left: 15px;
      color: #fff;
      font-weight: bolder;
      white-space: nowrap;
    }
    &.show {
      opacity: 1;
    }
  }
}
</style>
