<template>
  <div class="page" :class="{ show }">
    <div v-if="tab===1">
      <div class="title">经营板块数据</div>
      <div class="plate-data">
        <div class="plate-data-item" v-for="(item, index) in list" :key="index">
          <div class="plate-data-item-title">{{ item.title }}</div>
          <div class="plate-data-item-data">         
            <num v-if="item.value || item.value === 0" cls="contract-plate-num" :value="item.value" :percent="item.percent"></num>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="title">经营区域数据</div>
      <div class="data">
        <div class="data-item" v-for="(item, index) in list" :key="index">
          <div class="data-item-title">{{ item.title }}</div>
          <div class="data-item-data">
            <num v-if="item.value || item.value === 0" cls="contract-region-num" :value="item.value" :percent="item.percent"></num>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="tab" :class="{ bottom: tab == 1 }">
      <div class="tab-item" :class="{ active: tab === index}" v-for="(item, index) in tabs" :key="index">{{ item }}</div>
    </div> -->
    <slot></slot>
  </div>
</template>

<script>
import Num from "@/components/num.vue"

export default {
  name: 'Page',
  components: { Num },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isScale: {
      type: Boolean,
      default: false
    },
    isScaleAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabs: ['按地区', '按板块'],
      tab: 0,
      list: [],
      isInit: true,
    }
  },
  methods: {
    async setData(tab, list) {
      this.tab = tab
      // 先用0占位
      if (this.isInit) {
        this.list = list.map(i => {
          let len = String(i.value).split('')
          let val = ''
          for(let i of len) {
            val += '0'
          }
          return Object.assign({}, i, { value: val })
        })
        this.isInit = false
      }
      // 再用真实值替换
      this.$nextTick(async() => {
        this.list = list
      })
    }
  },
  destroyed() {
    this.list = []
    this.tab = 0
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // padding: 36px 45px;
  padding: 40px 50px;
  overflow: hidden;
  height: 100vh;
  // background: url('~@/assets/images/bg@2x.png') no-repeat;
  // background-size: cover;
  // background-position: left center;
  color: $font-color;
  opacity: 0;
  transition: opacity 1s ease;
  &.show {
    opacity: 1;
  }

  .title {
    margin-bottom: 42px;
    // font-size: 32px;
    font-size: 36px;
    font-weight: bold;
    @include font-oppo-b;
  }
  .plate-data {
    position: absolute;
    top: 110px; //130px;
    left: 50px;
    bottom: 383px; // 284px;
    // max-height: 638px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    display: grid;
    grid-template-rows: repeat(3fr);
    width: 310px;
    padding: 50px 40px 0;
    background: linear-gradient(221deg, #FF7635 0%, #9e3ec1 50%,#8831E2 100%);
    border-radius: 12px;

    &-item {
      &:not(:last-of-type) {
        // margin-bottom: 25px; //50px;
      }
      color: #fff;
      &-title {
        font-size: 14px;
        opacity:.8;
        @include font-oppo-m;
      }
      &-data {
        font-size: 40px;
        // line-height: 59px;
        @include font-din;
      }
    }
  }
  .data {
    &-item {
      display: inline-block;
      margin-right: 72px;
      &-title {
        font-size: 14px;
        @include font-oppo-m;
      }
      &-data {
        // font-size: 45px;
        // line-height: 52px;
        font-size: 50px;
        line-height: 59px;
        font-weight: bold;
        @include font-din;
        &.pect {
          color: $font-color-high;
        }
      }
    }
  }

  .tab {
    position: absolute;
    // right: 45px;
    // width: 385px;
    // height: 48px;
    // top: 167px;
    top: 150px;
    right: 50px;
    width: 245px;
    height: 54px;
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.8);
    border-radius: 11px 11px 11px 11px;
    box-shadow: 0 18px 32px 0 rgba($color: #d6d1e8, $alpha: 0.55);
    transition: .3s ease-in-out;
    &.bottom {
      top: calc(100vh - 292px);
    }

    &-item {
      position: relative;
      flex: 1;
      // padding-left: 49px;
      padding-left: 56px;
      height: 18px;
      font-size: 14px;
      color: $font-color-2;
      @include font-oppo-m;
      &:first-of-type {
        border-right: 1px solid rgba(37,37,37,0.25);
      } 
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        // left: 26px;
        left: 30px;
        width: 14px;
        height: 14px;
        background-color: $font-color-2;
        border-radius: 4px;
      }
      &.active {
        color: $font-color;
        &:after {
          background-color: $font-color;
        }
      }
    }
  }
}
</style>