<template>
  <div class="line-chart2">
    <div class="chart-title"><img src="@/assets/images/contract/icon_curve_jingying@2x.png" />每日预估营收实时曲线</div>
    <div class="chart-axis-text">营收 (万元)</div>
    <div class="chart-wrap" :id="chartId"></div>
    <div class="chart-label" v-if="pointPositions.length" :class="{ show: showLabel }">
      <div class="point" v-for="(item, index) in list" :key="item.id" :style="pointStyle(index, item)"></div>
      <div
        class="label"
        :class="{ show: index !== currentPointIndex, bottom: item.position == 'bottom' }"
        v-for="(item, index) in list"
        :key="index"
        :style="labelStyle(index, item)"
      >
        {{ item.value }}  
      </div>
    </div>
    <div class="chart-data" :class="{ show: showLabel }" :style="lineStyle">
      <div class="chart-data-label" v-if="list[currentPointIndex]">{{ list[currentPointIndex].value | numTrans }} <span>(万元)</span></div>
    </div>
    <!-- <img src="@/assets/images/contract/position@2x.png" class="chart-position" /> -->
  </div>
</template>

<script>
import utils from "@/assets/javascripts/utils"

export default {
  name: "plateLineChart",
  data() {
    return {
      chartId: "",
      $chart: null,
      list: [],
      pointPositions: [],
      currentPointIndex: 0,
      animateTimer: null,
      showLabel: false,
    }
  },
  computed: {
    lineStyle() {
      try {
        const { x, y } = this.pointPositions[this.currentPointIndex]
        return {
          top: y - 20 + "px",
          left: x + "px",
        }
      } catch (err) {
        return {}
      }
    },
  },
  methods: {
    getOptionData(list) {
      let dataList = []
      for (let i = 0; i < list.length; i++) {
        let position = "top"
        let { contractDate, contractAmount } = list[i]
        if (i !== list.length - 1) {
          if (contractAmount < list[i + 1].contractAmount) position = "bottom"
        }
        dataList.push({
          id: 'lineChart2_'+Date.now()*Math.random(),
          contractDate,
          label: utils.dateFormat(new Date(contractDate), 'mm-dd'),
          name: contractDate + Math.random(),
          value: utils.transToTenthousand(contractAmount),
          position,
        })
      }
      return dataList
    },
    initChart(chartId, list) {
      const dataLabels = list.map((i) => utils.dateFormat(new Date(i.contractDate), 'mm-dd'))

      let dataList = this.getOptionData(list)
      this.list = dataList

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          top: "20%",
          left: "5%",
          right: "7%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisTick: false,
            axisLabel: {
              color: "#996DA7",
              margin: 30,
            },
            axisLine: {
              lineStyle: {
                width: 1,
                color: "rgba(0,0,0,.12)",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                width: 1.2,
                color: "rgba(0,0,0,.15)",
              },
            },
            boundaryGap: false, //[1, 1],
            data: dataLabels,
          },
        ],
        yAxis: [
          {
            type: "value",
            scale: true,
            splitLine: {
              show: false,
            },
            axisLabel: {
              padding: [0, 30, 0, 0],
              textStyle: {
                color: "#996DA7",
                fontWeight: 500,
              },
            },
          },
        ],
        series: [
          {
            id: "line",
            type: "line",
            symbol: 'none',
            // symbolSize: 9,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(245, 167, 241, 0.50)",
                },
                {
                  offset: 1,
                  color: "rgba(238, 116, 249, 0)",
                },
              ]),
            },
            labelLine: {
              show: false,
              length2: 500,
              minTurnAngle: 120,
              lineStyle: {
                width: 2,
                join: "miter",
                miterLimit: 10,
                color: "#A245D4",
              }
            },
            label: {
              show: false,
              position: "top",
              fontWeight: "bold",
              color: "#A245D4",
              fontSize: 16,
              padding: [-30, 25],
              // offset: [30, -25],
              // formatter: function (params) {
              //   // 实现
              //   const { data, value } = params
              //   return `{${data.position}|${value}}`
              // },
              // textStyle: {
              //   rich: {
              //     bottom: {
              //       padding: [0, 0, -90, 0],
              //       verticalAlign: "top",
              //       fontSize: 16,
              //       fontWeight: "bold",
              //     },
              //     hide: {
              //       color: 'transparent',
              //       padding: 0,
              //     }
              //   },
              // },
            },
            lineStyle: {
              // color: "#E955EC",
              width: 4,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#9E49F4'//'rgba(233, 86, 236, 1)' // 0% 处的颜色
                }, {
                    offset: 1, color: '#EC56CB' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            itemStyle: {
              normal: {
                color: "#A245D4",
              },
            },
            smooth: 0.3,
            data: dataList,
          },
        ],
      }

      this.chartId = chartId
      this.$nextTick(() => {
        if (!this.$chart) {
          this.$chart = this.$echarts.init(document.getElementById(this.chartId))
        }
        this.$chart.setOption(option)

        this.initLabel()
      })
    },
    pointStyle(index, data) {
      const { x, y } = this.pointPositions[index]
      let top = y + 30
      let left = x - 5

      return {
        top: top + "px",
        left: left + "px",
      }
    },
    labelStyle(index, data) {
      const { x, y } = this.pointPositions[index]
      let top = y - 10
      let left = x + 5
      if (data.position === "bottom") top = top + 50

      return {
        top: top + "px",
        left: left + "px",
      }
    },
    async initLabel() {
      let model = this.$chart.getModel().getSeriesByIndex(0).getData()._layout
      let points = model.points

      this.pointPositions = []
      points.forEach((i, index) => {
        if (index % 2 == 0) {
          this.pointPositions.push({ x: i - 1 })
        } else {
          this.pointPositions[this.pointPositions.length - 1].y = i
        }
      })

      await utils.sleep(1000)
      this.showLabel = true
      await utils.sleep(2000)
      this.labelAnimation()
    },
    labelAnimation() {
      // clearInterval(this.animateTimer)

      // const setChartLabel = () => {
      //   for (let i=0; i<this.list.length; i++) {
      //     if (i == this.currentPointIndex) {
      //       this.list[i].show = false
      //     } else {
      //       this.list[i].show = true
      //     }
      //   }
      // }

      const setSeriesData = () => {
        const nextData = this.list.shift()
        this.list.push({
          ...nextData,
          name: nextData.name + Math.random(),
          id: 'lineChart2_'+Date.now()*Math.random(),
        })
        const dataLabels = this.list.map((i) => i.label)
        if (this.$chart) {
          this.$chart.setOption({
            xAxis: {
              data: dataLabels,
            },
            series: [
              {
                data: this.list,
              },
            ],
          })
          this.initLabel()
        }
      }

      const func = () => {
        const len = this.pointPositions.length
        if (this.currentPointIndex >= len - 1) {
          this.currentPointIndex = 0
        } else {
          this.currentPointIndex++
        }
        // setChartLabel()
        // setSeriesData()
      }

      // setChartLabel()
      this.animateTimer = setInterval(func, 3000)
      func()
    },
    updateData(data) {
      this.list = this.list.map((i) => {
        const { contractAmount } = data.find((_i) => _i.contractDate == i.contractDate)
        return Object.assign(i, {
          value: utils.transToTenthousand(contractAmount),
        })
      })
    },
  },
  destroyed() {
    this.$chart.dispose()
    this.$chart = null
    this.chartId = null
    clearInterval(this.animateTimer)
    this.animateTimer = null
  },
}
</script>

<style lang="scss" scoped>
.line-chart2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  .chart-title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    font-size: 22px;
    @include font-oppo-b;
    color: #1c104c;
    line-height: 26px;
    img {
      margin-right: 10px;
      height: 27px;
    }
  }
  .chart-axis-text {
    position: absolute;
    left: 0;
    top: 60px;
    color: #996DA7;
    font-size: 12px;
  }
  .chart-wrap {
    height: calc(100% - 36px - 110px);
  }

  .chart-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100% - 36px - 111px);
    opacity: 0;
    &.show {
      transition: 0.5s ease;
      opacity: 1;
    }
    .point {
      position: absolute;
      top: 0;
      left: 0;
      width: 12px;
      height: 12px;
      border: 2px solid #A245D4;
      background: #fff;
      border-radius: 50%;
      z-index: 10;
      transition: .3s ease;
    }
    .label {
      position: absolute;
      margin-bottom: 10px;
      padding: 0px 0px 20px 15px;
      opacity: 0;
      font-size: 16px;
      font-weight: bold;
      color: #A245D4;
      transition: 0.3s ease;
      &.show {
        opacity: 1;
      }
      &.bottom {
        &:before {
          top: calc(-100% + 40px);
          left: 1px;
          transform: rotate(150deg);
        }
        &:after {
          top: 22px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 19px;
        left: 2px;
        height: calc(100% - 15px);
        width: 2px;
        background: #A245D4;
        transform: rotate(30deg);
      }
      &:after {
        content: "";
        position: absolute;
        top: 20px;
        left: 8px;
        width: calc(100% - 8px);
        height: 2px;
        background: #A245D4;
      }
    }
  }

  .chart-position {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 86px;
  }

  .chart-data {
    position: absolute;
    top: 20px;
    left: 0;
    bottom: 151px;
    width: 1px;
    border: 1px dashed #A245D4;
    transition: 0.5s ease;
    opacity: 0;
    &.show {
      opacity: 1;
    }
    &-label {
      position: absolute;
      top: -45px;
      left: -50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      padding: 6px 12px;
      color: #fff;
      font-size: 18px;
      @include font-din;
      height: 35px;
      background: linear-gradient( 220deg, #FF7B69 0%, #AC57EF 100%);
      border-radius: 8px;
      white-space: nowrap;
      span {
        margin-left: 4px;
        font-size: 14px;
        @include font-oppo-b;
      }
    }
  }
}
</style>
