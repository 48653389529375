<template>
  <Page ref="pageRef" :show="showPage">
    <div class="left-chart">
      <RegionMapChart ref="mapChartRef"></RegionMapChart>
    </div>
    <div class="right-chart">
      <RegionLineChart ref="lineChartRef" @next="regionNext"></RegionLineChart>
    </div>
  </Page>
</template>

<script>
import Page from "./components/page"
import RegionLineChart from "./components/regionLineChart.vue"
import RegionMapChart from "./components/regionMapChart.vue"
import utils from '@/assets/javascripts/utils'
import _ from 'lodash'

export default {
  components: { Page, RegionLineChart, RegionMapChart },
  props: {
    isScale: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPage: true,
      area: ["北京市", "上海市", "广东省", "广东省", ["云南省", "贵州省", "四川省"]],
      fetchTimer: null,
      mapTimer: null,
      activeIndex: 0,
      regionList: []
    }
  },
  mounted() {
    this.initPage()
    // 轮询更新数据
    this.autoRefreshData()
  },
  methods: {
    async initPage() {
      this.$emit("show")
      this.getData()
      this.getMapData()
    },
    // 合同数据
    async getData() {
      const res = await this.$api.contract.getIndexData()
      let {
        amounts,
        contractAmount,
        contractRiseAmount,
        contractPublishAmount,
        contractRisePublishAmount,
        contractPaymentAmount,
        contractRisePaymentAmount,
      } = res
      this.$nextTick(async () => {
        this.$refs.pageRef.setData(0, [
          // {
          //   title: new Date().getFullYear() + "年成交合同",
          //   value: contractNum,
          //   percent: contractRiseNum,
          // },
          // {
          //   title: "广告合同",
          //   value: contractGGNum,
          //   percent: contractRiseGGNum,
          // },
          // {
          //   title: "其他合同",
          //   value: contractOtherNum,
          //   percent: contractRiseOtherNum,
          // },
          {
            title: "预估总营收（万元）",
            value: utils.transToTenthousand(contractAmount),
            percent: utils.transToTenthousand(contractRiseAmount), 
            // percent: 57,  // remark: 给UI验收，后面需要换回去 
          },
          {
            title: "预估总登版（万元）",
            value: utils.transToTenthousand(contractPublishAmount),
            percent: utils.transToTenthousand(contractRisePublishAmount),
            // percent: 1249,  // remark: 给UI验收，后面需要换回去 
          },
          {
            title: "预估总回款（万元）",
            value: utils.transToTenthousand(contractPaymentAmount), 
            percent: utils.transToTenthousand(contractRisePaymentAmount), 
            // percent: -499,  // remark: 给UI验收，后面需要换回去 
          },
        ])

        // amounts = [
        //     {
        //         "contractDate": "2023-09-04",
        //         "contractAmount": 5232212,
        //         "contractNum": 46
        //     },
        //     {
        //         "contractDate": "2023-09-05",
        //         "contractAmount": 411212,
        //         "contractNum": 47
        //     },
        //     {
        //         "contractDate": "2023-09-06",
        //         "contractAmount": 721212,
        //         "contractNum": 51
        //     },
        //     {
        //         "contractDate": "2023-09-07",
        //         "contractAmount": 512112,
        //         "contractNum": 21
        //     },
        //     {
        //         "contractDate": "2023-09-08",
        //         "contractAmount": 32512,
        //         "contractNum": 11
        //     },
        // ]
      })
    },
    // 设置营收曲线图数据
    setLineData() {
      const { amounts, resourceName } = this.regionList[this.activeIndex]
      this.$refs.lineChartRef.initChart("lineChart", amounts, resourceName)
    },
    // 设置地图数据
    async getMapData(isInit = true) {
      const res = await this.$api.contract.getRegion()
      const { region } = res
 
      const areaData = {
        北京: {
          key: "beijing",
          coord: [116.46, 39.92],
          position: "right",
        },
        上海: {
          key: "shanghai",
          coord: [121.48, 31.22],
          position: "right",
        },
        深圳: {
          key: "shenzhen",
          coord: [114.07, 22.62],
          position: "right",
        },
        广州: {
          key: "guangzhou",
          coord: [113.23, 23.16],
          position: "left",
        },
        西南: {
          key: "xinan",
          coord: [104.06, 30.67],
          position: "left",
        },
      }
      let city = [] //region.map((i) => Object.assign(i, areaData[i.resourceName]))
      for (let i in areaData) {
        let data = region.find(item => item.resourceName == i)
        city.push(Object.assign(data, areaData[i]))
      }
      this.regionList = city
      let result = {
        area: this.area,
        city,
      }
      if (isInit) {
        this.$refs.mapChartRef.initChart("mapChart", result)
        // this.setMapAnimation()
        this.setLineData()
      } else {
        this.$refs.mapChartRef.setData(result)
      }
    },
    // 加载下一个区域数据
    regionNext() {
      if (this.activeIndex == this.regionList.length - 1) {
        this.activeIndex = 0
      } else {
        this.activeIndex++
      }
      this.$refs.mapChartRef.setActive(this.activeIndex)
      this.setLineData()
    },
    // 轮询更新
    autoRefreshData() {
      this.fetchTimer = setInterval(() => {
        this.getData()
        this.getMapData(false)
      }, 3 * 60 * 1000)
    },
  },
  beforeDestroy() {
    clearInterval(this.fetchTimer)
    clearInterval(this.mapTimer)
    this.fetchTimer = null
    this.mapTimer = null
  },
}
</script>

<style lang="scss" scoped>
.left-chart {
  position: absolute;
  top: 260px;
  left: 20px;
  bottom: 0px;
  width: 51%;
  // background: #f0f;
}

.right-chart {
  position: absolute;
  // top: 259px;
  // bottom: 73px;
  // width: 47.8%;
  top: 239px; //239px;
  right: 50px; //50px;
  bottom: 53px;
  width: 45%; // 50%;
}
</style>
