<template>
  <DynamicScroller
    v-if="list.length"
    :items="list"
    :direction="direction"
    :buffer="buffer"
    :prerender="prerender"
    :min-item-size="miniItemSize"
    class="scroll-list"
    ref="scrollList"
    @scroll-end="mediumScrollEnd"
  >
    <template v-slot="{ item, index, active }"> 
      <slot :item=item :index=index :active=active></slot>
    </template>
  </DynamicScroller>
</template>

<script>
import _ from 'lodash'
export default {
  name: "DynamicScroll",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    direction: {
      type: String,
      default: 'horizontal'
    },
    miniItemSize: {
      type: Number,
      default: 100
    },
    buffer: {
      type: Number,
      default: 100
    },
    prerender: {
      type: Number,
      default: 0
    },
    speed: {
      type: Number,
      default: 0.5
    }
  },
  data() {
    return {
      loading: false,
      dataKey: 0,
      pauseScroll: false,
      rid: null, // 动画实例id
    }
  },
  methods: {
    mediumScrollEnd() {
      // 插件提供的scrollend只有第一次才触发，没找到原因先自己实现了
      // this.$emit('scrollEnd')
    },
    scrollEnd: _.throttle(function (){
      this.$emit('scrollEnd')
    }, 1000),
    mediumScroll(e) {
      const parentEl = e.target
      const parentElWidth = parentEl.clientWidth
      const scrollWidth = parentEl.children[0].clientWidth
      if (parentElWidth + e.target.scrollLeft >= scrollWidth - this.buffer) {
        this.scrollEnd()
      }
    },
    scrollLeft(value, speed = 10) {
      const scrollListRef = this.$refs.scrollList.$el
 
      let left = value
      let scrollLeft = scrollListRef.scrollLeft

      const func = () => {
        if (left <= 0) return
        // scrollLeft = scrollLeft + speed
        // left -= speed

        // step越小，先快后慢
        let step = left / speed;
        // 当step大于0时，step向上取整，否则，step向下取整
        step = step > 0 ? Math.ceil(step) : Math.floor(step);
        scrollLeft = scrollLeft + step
        left -= step

        // console.log({scrollLeft})
        scrollListRef.scrollLeft = scrollLeft
        requestAnimationFrame(func)
      }
      requestAnimationFrame(func)
    },
  },
}
</script>

<style lang="scss" scoped>
.scroll-list {
  width: 100%;
  height: 100%;
  @include scrollBar($width: 0px);
}
</style>
