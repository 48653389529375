<template>
  <div class="map-chart">
    <div :id="chartId" ref="chartRef"></div>
    <div class="label-list" v-if="loadLabel">
      <div class="label" v-for="(item, index) in data.city" :key="item.name" :style="getPos(item)" :class="{ [item.key]: item.key, 'label-left': item.position === 'left', active: index === cityActiveIdx}">
        <div class="label-tag"></div>
        <div class="label-info">
          <div class="label-info-title"><span>{{ item.resourceName }}</span></div>
          <div class="label-info-content">
            <div class="content-item">预估总营收 <span class="blue">{{ utils.transToTenthousand(item.contractAmount) | numTrans }}万</span></div>
            <div class="content-item">预估总登版 <span class="blue">{{ utils.transToTenthousand(item.contractPublishAmount) | numTrans }}万</span></div>
            <div class="content-item">预估总回款 <span class="blue">{{ utils.transToTenthousand(item.contractPaymentAmount) | numTrans }}万</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import geoJson from '@/assets/javascripts/geojson.json'
import utils from '@/assets/javascripts/utils'

export default {
  name: "regionMapChart",
  data() {
    return {
      chartId: "",
      $chart: null,
      data: {},
      loadLabel: false,
      cityActiveIdx: 0,
      utils
    }
  },
  methods: {
    getPos(data) {
      const pos = this.$chart.convertToPixel('geo', data.coord)
      const top = pos[1] - 44 + 'px'
      const left = pos[0] + -10 + 'px'
      return {
        top,
        left
      }
    },
    getOptionData(list, activeIdx) {
      const areaColorConfig = {
        'normal': [
            {
              offset: 0,
              color: "#FF7AE7",
            },
            {
              offset: 1,
              color: "#FD8AAC",
            },
        ],
        'active': [
          {
            offset: 0,
            color: "#FF6A40",
          },
          {
            offset: 1,
            color: "#4839FF",
          },
        ]
      }

      let data = []
      let activeProvince = list[activeIdx]
   
      list.forEach((i, index) => {
        let areaColor = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, areaColorConfig.normal)
        if (Array.isArray(list[index])) {
          list[index].forEach(_i => {
            if (activeProvince.includes(_i)) {
              areaColor = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, areaColorConfig.active)
            }
            data.push({
              name: _i,
              itemStyle: {
                areaColor //默认区域颜色 
              },
            })
          })        
        } else {
          if (i === activeProvince) {
            areaColor = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, areaColorConfig.active)
          } 
        }
        data.push({
          name: i,
          itemStyle: {
            areaColor 
          },
        })
      })

      return data
    },
    initChart(chartId, data) {
      this.data = data

      const optionData = this.getOptionData(data.area, 0)
      const size = this.$refs.chartRef.offsetWidth - 100
     
      const option = {
        // 开启散点图才需要配置geo
        geo:{
            map:'CHINA',
            show:true,
            roam:false,   
            layoutCenter: ['50%', '50%'],
            layoutSize: size //840, 
        },
        series: [
          {
            name: 'map',
            type: 'map',
            map: 'CHINA',
            data: optionData,
            layoutCenter: ['50%', '50%'],
            layoutSize: size, //840, 
            universalTransition: {
              enabled: true
            },
            itemStyle: {
              borderWidth: 0.5,//边际线大小
              borderColor:'#CAA393',//边界线颜色
              areaColor: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "#FBF1FE",
                },
                {
                  offset: 1,
                  color: "#F7CCF2",
              }]) //默认区域颜色
            }
          },
        ],
      }

      this.chartId = chartId
      this.$nextTick(() => {
        if (!this.$chart) {
          this.$chart = this.$echarts.init(document.getElementById(this.chartId))
          this.$echarts.registerMap('CHINA', {geoJSON: geoJson});
        }
        this.$chart.setOption(option)
        this.$nextTick(() => {
          this.loadLabel = true
        })
      })
    },
    setData(data) {
      this.data = data
    },
    setActive(activeIdx) {
      const data = this.getOptionData(this.data.area, activeIdx)

      this.cityActiveIdx = activeIdx
      const option = {
        series: [
          {
            data
          },
        ]
      }
      this.$chart.setOption(option)
    },
  },
  destroyed() {
    this.$chart.dispose()
    this.$chart = null
    this.chartId = null
  },
}
</script>

<style lang="scss" scoped>
.map-chart {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // background-color: #f00;
  & > div {
    height: 100%;
  }
  .label-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    .label {
      position: absolute;
      transition: 1s linear;
      &.label-left {
        .label-info {
          left: -170px; //-180px;
        }
      }
      &.beijing {
        z-index: 10;
        .label-info {
          top: -38px;
          left: 38px;
        }
      }
      &.shanghai {
        z-index: 9;
        .label-info {
          top: -20px;
          left: 30px;
        }
      }
      &.shenzhen {
        z-index: 8;
        .label-info {
          top: -5px;
          left: 30px;
        }
      }
      &.xinan {
        .label-info {
          top: -5px;
        }
      }
      &.active {
        transform: scale(1.1);
        z-index: 19;
        .label-tag {
          transform: scale(1.5);
          background-image: url('~@/assets/images/contract/icon_location_on@2x.png');
        }
        .label-info {
          &-content {
            // background-color: #3847F9;
            background: linear-gradient( 221deg, #A145D6 0%, #3847F8 100%);
          }
          .content-item {
            color: rgba(255,255,255,0.65);
            span {
              color: #fff !important;
              background: transparent;
              -webkit-text-fill-color: #fff;
            }
          }
        }
      }

      &-tag {
        width: 22px;
        height: 44px;
        background: url('~@/assets/images/contract/icon_location_off@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
        transition: 1s linear;
        transform-origin: center bottom;
      }
      &-info {
        position: absolute;
        top: 18px;
        left: 22px;
        // width: 186px;
        // height: 138px;
        background: rgba(255,255,255,0.4);
        border-radius: 12px;
        box-shadow: 0px -10px 20px 0px rgba(220,220,220,0.25);
        overflow: hidden;
        &-title {
          position: relative;
          padding: 15px 20px;
          height: 48px;
          span {
            position: relative;
            z-index: 1;
            font-size: 14px;
            @include font-oppo-b;
            color: #3A2A2A;
            line-height: 16px;
          }
          &:after {
            content: '';
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: -20px;
            // opacity: .15;
            background: rgba(255,255,255,0.4);
            // background: linear-gradient( 180deg, #FFFFFF 0%, #FE85BF 100%);
          }
        }
        &-content {
          position: relative;
          z-index: 1;
          padding: 15px 20px 9px 20px;
          // height: 69px;
          transition: 1s linear;
          border-radius: 12px;
          background: linear-gradient( 70deg, #FFD5C5 19%, #DB99FF 100%);
          box-shadow: 0px -10px 20px 0px rgba(220,220,220,0.25);
          .content-item {
            margin-bottom: 6px;
            font-size: 12px;
            @include font-oppo-m;
            color: #A9889B;
            line-height: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              position: relative;
              top: 2px;
              margin-left: 3px;
              color: #1C104C;
              @include font-din;
              font-weight: bold;
              font-size: 14px;
              transition: 1s linear;
            }
          }
        }
      }
    }
  }
  // .red {
  //   background: linear-gradient(219deg, #E48BDE 0%, #F97491 100%);
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  // }
  // .blue {
  //   color: #574AF2; 
  // }
}

</style>
