<!--
 * @Description: 实现数字翻牌动态效果，css 属性 writing-mode: vertical-lr，使数字竖直排版，
        2d移动 transform: translate(-50%, -40%); y值控制移动至哪个数字，transition
        控制transform属性有动画效果
 -->
<template>
  <div class="num" :class="cls" ref="numRef">
    <div class="box-item">
      <li
        :class="{ 'number-item': !isNaN(item), 'mark-item': isNaN(item) }"
        v-for="(item, index) in orderNum"
        :key="index"
      >
        <span v-if="!isNaN(item)">
          <i class="letter">0123456789</i>
        </span>
        <span class="comma" v-else>{{ item }}</span>
      </li>
    </div>
    
    <span class="percent" :class="percentCls" v-if="percent">{{ Math.abs(percent) }}{{ percentUnit }}</span>
  </div>
</template>
<script>
import utils from '@/assets/javascripts/utils'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    percent: {
      type: [Number, String],
      default: 0
    },
    percentUnit: {
      type: String,
      default: ''
    },
    cls: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
    }
  },
  computed: {
    orderNum: {
      get() { 
        if (!this.value) return ['0']
        let str = typeof this.value == 'string'? this.value:this.value.toString()
        const _str = utils.getNumTrans(str)
        return _str.split('')
      },
      set() {}
    },
    percentCls() {
      let percent = this.percent
      if (typeof percent == 'string') {
        percent = parseInt(percent)
      }
   
      return { up: percent>0, down: percent<0}
    }
  },
  mounted() {
    setTimeout(() => {
      this.toOrderNum(this.value) 
    }, 500)
  },
  // watch: {
  //   value: {
  //     handler(v) {
  //       setTimeout(() => {
  //         this.toOrderNum(v)
  //       }, 500)
  //     }
  //   },
  //   immediate: true
  // },
  methods: {
    // 设置文字滚动
    setNumberTransform() {
      const numRef = this.$refs.numRef
      const letterItems = numRef.querySelectorAll('.letter')
      const numberArr = this.orderNum.filter((item) => !isNaN(item))
      // 结合CSS 对数字字符进行滚动,显示订单数量
      for (let index = 0; index < letterItems.length; index++) {
        const elem = letterItems[index]
        elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`
      }
    },
    // 处理总订单数字
    async toOrderNum(num) {
      if (typeof num !== 'string') {
        num = num.toString()
      }
      // 数字变成字符串
      if (num.length < 12) {
        num = "0" + num // 如未满八位数，添加"0"补位
        this.toOrderNum(num) // 递归添加"0"补位
      } else if (num.length === 12) {
        // 数字中加入逗号
        // num = num.slice(0, 2) + ',' + num.slice(2, 5) + ',' + num.slice(5, 8)
        this.orderNum = num.split("") // 将其便变成数据，渲染至滚动数组
      } else {
        // 订单总量数字超过八位显示异常
        this.$message.warning("总量数字过大")
      }
      this.setNumberTransform()
    },
  },
}
</script>
<style scoped lang="scss">
.num {
  display: inline-flex;
  align-items: flex-end;
  &.contract-region-num {
    height: 70px;
    .box-item {
      height: 65px;
      font-size: 60px;
    }
    .percent {
      bottom: 0;
      padding-left: 16px;
      font-size: 16px;
    }
    .number-item {
      width: 33px;
    }
  }
  &.contract-plate-num {
    position: relative;
    display: block;
    height: 85px; //75px;
    .box-item {
      font-size: 50px; // 45px;
    }
    .number-item {
      width: 28px;
    }
    .percent {
      position: absolute;
      bottom: 0;
      margin-left: 0px;
      padding-left: 21px;
      font-size: 20px; // 20px;
      line-height: 20px; // 24px;
      height: 20px; // 24px;
      @include font-din;
      color: #fff !important;

      &:after {
        content: '';
        bottom: 3px; 
        width: 13px;
        height: 12px;
        background-size: contain;
        background-position: left center;
      }
      &.up {
        &:after {
          background-image: url('~@/assets/images/contract/icon_arrow_up_white@2x.png');
        }
      }
      &.down {
        &:after {
          bottom: 1px;
          background-image: url('~@/assets/images/contract/icon_arrow_down_white@2x.png');
        }
      }
    }
  }
  &.content-article-num {
    .box-item {
      font-size: 30px;
      height: 34px;
    }
    .mark-item {
      top: -4px;
      font-size: 30px;
    }
    .number-item {
      width: 17px;
    }
  }
  &.content-flow-num {
    .percent {
      padding-left: 18px !important;
      color: #fff !important;
      &:after {
        top: 2px !important;
        background-image: url('~@/assets/images/content/icon_arrow_down_white@2x.png') !important;
      }
    }
    .box-item {
      font-size: 40px;
      line-height: 46px;
    }
    .mark-item {
      top: -8px;
      font-size: 40px;
    }
  }
  /*订单总量滚动数字设置*/
  .box-item {
    position: relative;
    height: 52px;
    text-align: center;
    list-style: none;

    font-size: 45px;
    line-height: 52px;
    font-weight: bold;
    writing-mode: vertical-lr;
    text-orientation: upright;
    /*文字禁止编辑*/
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
    /* overflow: hidden; */
  }
  /* 默认逗号设置 */
  .mark-item {
    width: 10px;
    height: 100%;
    margin: 5px 2px 0;
    line-height: 10px;
    font-size: 50px;
    position: relative;
    & > span {
      position: absolute;
      width: 100%;
      bottom: 0;
      writing-mode: vertical-rl;
      text-orientation: upright;
      @include font-din;
    }
  }
  /*滚动数字设置*/
  .number-item {
    width: 24px;
    height: 100%;
    list-style: none;
    // margin-right: 5px;
    & > span {
      position: relative;
      display: inline-block;
      margin-right: 10px;
      width: 100%;
      height: 100%;
      writing-mode: vertical-rl;
      text-orientation: upright;
      overflow: hidden;
      & > i {
        font-style: normal;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0);
        transition: transform 1s ease-in-out;
        letter-spacing: 10px;
        @include font-din;
      }
    }
  }
  .number-item:last-child {
    margin-right: 0;
  }
  .percent {
    position: relative;
    bottom: 4px;
    margin-left: 6px;
    padding-left: 14px;
    font-size: 13px;
    height: 13px;
    line-height: 13px;
    color: rgba(233, 80, 4, 1);
    font-weight: bold;
    &:after {
      content: '';
      position: absolute;
      bottom: 3px;
      left: 2px;
      width: 11px;
      height: 9px;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.up {
      &:after {
        background-image: url('~@/assets/images/contract/icon_arrow_up@2x.png');
      }
    }
    &.down {
      color: rgba(33, 159, 103, 1);
      &:after {
        background-image: url('~@/assets/images/contract/icon_arrow_down@2x.png');
      }
    }
  }
}
</style>
