<template>
  <div class="page" :class="{ show }">
    <div class="content-article" v-if="showType == 'contentArticle'">
      <div class="title">内容生产数据</div>
      <div class="data">
        <div class="data-item" :class="{light: index == 0}" v-for="(item, index) in list" :key="index">
          <div class="data-item-title">{{ item.title }}</div>
          <div class="data-sub">
            <div class="data-sub-item" v-for="(sub, sIndex) in item.children" :key="sIndex">
              <div class="sub-item-title" :style="index == 0 && 'color: #fff;'">{{ sub.title }}</div>
              <div class="sub-item-data" :style="index == 0 && 'color: #fff;'">
                <num v-if="sub.value || sub.value === 0" cls="content-article-num" :value="sub.value" :percent="sub.percent" :percentUnit="sub.percentUnit"></num>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-flow" v-if="showType == 'contentFlow'">
      <div class="title">内容流量数据</div>
      <div class="data">
        <div class="data-item" :class="{light: index == 0}" v-for="(item, index) in list" :key="index">
          <div class="data-item-title">{{ item.title }}</div>
          <div class="data-item-data" :class="{ pect: item.percent }">
            <num v-if="item.value || item.value === 0" cls="content-flow-num" :value="item.value" :percent="item.percent" :percentUnit="item.percentUnit"></num>
          </div>
        </div>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import Num from "@/components/num.vue"

export default {
  name: 'Page',
  components: { Num },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showType: {
      type: String,
      default: ''
    },
    isScale: {
      type: Boolean,
      default: false
    },
    isScaleAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      isInit: true
    }
  },
  methods: {
    async setData(list) {
      if (this.isInit) {
        this.list = list.map(i => {
          let len = String(i.value).split('')
          let val = ''
          for(let i of len) {
            val += '0'
          }
          return Object.assign({}, i, { value: val })
        })
        this.isInit = false
      }
      this.$nextTick(async() => {
        this.list = list
      })
    }
  },
  destroyed() {
    this.list = []
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // padding: 36px 45px;
  padding: 40px 50px;
  overflow: hidden;
  height: 100vh;
  // background: url('~@/assets/images/bg@2x.png') no-repeat;
  // background-size: cover;
  // background-position: left center;
  color: $font-color;
  opacity: 0;
  transition: opacity 1s ease;

  &.show {
    opacity: 1;
  }

  .title {
    margin-bottom: 42px;
    // font-size: 32px;
    font-size: 36px;
    font-weight: bold;
    @include font-oppo-b;
  }

  .data {
    &-item {
      display: inline-block;
      margin-right: 16px;
      padding: 18px 22px;
      height: 127px;
      background: #fff;
      border-radius: 12px;
      box-shadow: 0 30px 30px 0 rgba($color: #F6789E, $alpha: 0.15);
      &-title {
        // margin-bottom: 4px;
        // font-size: 14px;
        color: #1C104C;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
        @include font-oppo-m;
      }
      &-data {
        // font-size: 45px;
        // line-height: 52px;
        font-size: 40px;
        line-height: 49px;
        font-weight: bold;
        color: #A245D4;
        @include font-din;
        &.pect {
          color: $font-color-high;
        }
      }
    }
    .light {
      background: linear-gradient( 245deg, #A145D6 0%, #3847F8 100%);
      .data-item-title {
        color: #fff;
      }
      .data-item-data {
        color: #fff;
      }
      .data-sub-item {
        &:nth-child(1) {
          .sub-item-data {
            &:after {
              background: rgba(255,255,255,0.25);
            }
          }
        }
      }
      // :deep(.sub-item-title) {
      //     color: #fff !important;
      // }
      // :deep(.num.content-article-num) {
      //   color: #fff !important;
      // }
    }

    &-sub {
      display: flex;
      margin-top: 14px;
      .data-sub-item {
        flex: 1;
        // min-width: 30px;
        .sub-item-title {
          font-size: 14px;
          padding-right: 15px;
        }
        &:nth-child(1) {
          .sub-item-data {
            position: relative;
            padding-right: 23px;
            color: #A245D4;
            &:after {
              content: '';
              position: absolute;
              right: 0;
              top: 10px;
              width: 1px;
              height: 20px;
              background: rgba(37,37,37,0.25);
            }
          }
        }
        &:nth-child(2) {
          padding-left: 15px;
        }

      }
    }
  }

  .tab {
    position: absolute;
    // top: 108px;
    // right: 45px;
    // width: 385px;
    // height: 48px;
    top: 120px;
    right: 50px;
    width: 430px;
    height: 54px;
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.8);
    border-radius: 11px 11px 11px 11px;
    box-shadow: 0 18px 32px 0 rgba($color: #d6d1e8, $alpha: 0.55);

    &-item {
      position: relative;
      flex: 1;
      // padding-left: 49px;
      padding-left: 56px;
      height: 18px;
      font-size: 14px;
      color: $font-color-2;
      @include font-oppo-m;
      &:first-of-type {
        border-right: 1px solid rgba(37,37,37,0.25);
      } 
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        // left: 26px;
        left: 30px;
        width: 14px;
        height: 14px;
        background-color: $font-color-2;
        border-radius: 4px;
      }
      &.active {
        color: $font-color;
        &:after {
          background-color: $font-color;
        }
      }
    }
  }

  .content-flow {
    .data-item-title {
      margin-bottom: 15px;
    }
  }
}
</style>