<template>
  <Page
    class="article-page"
    ref="pageRef"
    showType="contentArticle"
    :show="showPage"
    :isScale="isScale"
  >
    <div class="medium" :class="{ scale: isScale }" v-if="mediumList.length">
      <DynamicScrollList
        :list="mediumList"
        direction="horizontal"
        :buffer="1000"
        :prerender="20"
        :min-item-size="100"
        ref="mediumScroll"
        class="medium-scroll-list"
      >
        <!-- @scrollEnd="mediumScrollEnd" -->
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :size-dependencies="[item.rank]"
            :data-index="index"
          >
            <div
              class="medium-item"
              :class="{ current: curAccountIndex == index }"
              :data-first="item.isFirst"
              :data-id="item.id"
              :data-key="item.key"
              :data-info="JSON.stringify(item)"
            >
              <div
                class="medium-item-content"
                :class="{ 'animate-bg-ball': mediumAnimate }"
              >
                <!-- <div style="position: absolute;top:20px;font-size:30px;z-index:10;left:20px"></div> -->
                <div class="ball" :style="ballStyle(item, index)"></div>
              </div>
              <div class="text">
                {{ item.account.name }}
                <div v-if="item.account.name !== item.platform.name">
                  {{ item.platform.name }}
                </div>
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScrollList>
      <div class="ball-animate" :style="animateBall1Style">
        <!-- <div class="ball-animate-inner"></div> -->
        <img
          src="@/assets/images/content/ball_378.gif"
          class="ball-animate-inner"
          @load="ballGifLoaded"
        />
        <!-- <img src="https://seal.timesmedia.com.cn/img/ball_378.8957e547.gif" class="ball-animate-inner" @load="ballGifLoaded" /> -->
      </div>
      <!-- <div class="ball-animate-2" :style="animateBall2Style"></div> -->
      <div class="ball-shadow" :style="shadowStyle"></div>
      <div class="detail" v-if="detailData" :style="detailStyle">
        <div class="detail-title">{{ detailData.name }}</div>
        <div class="detail-content">
          <img :src="detailData.account.avatar" class="detail-logo" />
          <div class="detail-text">更新量(篇)</div>
          <div class="detail-num">{{ detailData.articleNum }}</div>
          <div class="detail-bottom">
            <div class="detail-bottom-item">
              <div class="detail-text">稿件字数</div>
              <div class="detail-value">{{ detailData.wordCount | numTrans }}</div>
            </div>
            <div class="detail-bottom-item">
              <div class="detail-text">阅读量</div>
              <div class="detail-value">{{ detailData.articlePv | numTrans }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="article" :class="{ scale: isScale }" v-if="list.length">
      <scroll-list
        :list="list"
        :item-size="320"
        :buffer="300"
        :prerender="20"
        :speed="articleScrollSpeed"
        ref="scrollList"
        class="article-scroll-list"
        @scrollEnd="articleScrollEnd"
      >
        <template v-slot="{ item, index }">
          <div
            class="article-item"
            :class="{ new: index == 0 }"
            :data-info="JSON.stringify(item)"
          >
            <div
              class="article-item-time"
              :class="{ today: isToday(item.onlineAt) }"
              v-if="item.onlineAt"
            >
              {{ dateFormat(item.onlineAt) }}
            </div>
            <div class="article-item-content">
              {{ item.title }}
            </div>
            <div class="article-item-bottom">
              <img :src="item.account.avatar" class="logo" />
              {{ item.account.name }}
              {{ item.account.name == item.platform.name ? "" : item.platform.name }}
              {{ item.author }}
            </div>
          </div>
        </template>
      </scroll-list>
    </div>
  </Page>
</template>

<script>
import Page from "./components/page"
import ScrollList from "@/components/scrollList.vue"
import DynamicScrollList from "@/components/dynamicScrollList.vue"
import utils from "@/assets/javascripts/utils"

let page = 1
export default {
  components: { Page, ScrollList, DynamicScrollList },
  props: {
    isScale: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPage: false,
      utils,

      statData: {},
      mediumList: [],
      mediumOriginList: [], // 账号列表原始数据
      mediumMax: 0,
      mediumMin: 0,
      list: [],
      detailData: {},
      pageParam: {
        page: 1,
        perPage: 50,
      },
      pageData: {},
      mediumKey: 0,
      articleKey: 0,
      startLeft: 0, // 数据方块初始左坐标点
      tagWidth: 160, // 数据方块宽度
      curDetailIndex: 3,
      curAccountIndex: 3, // 当前账号球对应的数据data-index
      accountBallTimes: 2, // 账号球播放速度

      articleScrollSpeed: 1, // 底部文章滚动的速度
      articleMaxPageCount: 1, // 底部文章每个账号的文章最多显示的页数

      firstInitFinish: false, // 初次加载文章数据是否结束
      firstInitDetailIdIndex: 3, // 初次加载文章数据对应的栏目id的下标
      isFirstInitNextDetail: true, // 下一个详情是否是初次加载文章赋值的
      detailStyle: {}, // 中间数据方块样式
      animateBall1Style: {}, // 旋转球1样式
      animateBall2Style: {}, // 旋转球2样式
      shadowStyle: {}, // 阴影样式
      mediumAnimate: false,

      ballLoaded: false, // 球是否加载
      accountTimer: null,
    }
  },
  computed: {
    curDetailId() {
      return this.mediumList[this.curDetailIndex].id
    }
  },
  created() {
    this.$emit('loading')
  },
  mounted() {
    this.initPage()
    // window.addEventListener('click', ()=> this.articleScrollSpeed = window.speed)
  },
  methods: {
    async initPage() {
      this.getStatist()
      this.getAccount()
    },
    // 稿件统计数据
    async getStatist() {
      const res = await this.$api.content.getArticleStatist()
      this.statData = res
    },

    // 获取账号数据
    async getAccount(isInit = true) {
      const res = await this.$api.content.getAccountRank()
      let { items, rank } = res
      if (items.length === 0) return

      this.mediumOriginList = items.map((item) =>
        Object.assign(item, {
          id: this.getOnlyId(item.account.id, item.platform.id),
        })
      )
      this.mediumMax = rank.max
      this.mediumMin = rank.min

      items = items.map((item) =>
        Object.assign(item, {
          id: this.getOnlyId(item.account.id, item.platform.id),
          key: this.mediumKey++,
        })
      )
      if (isInit) {
        // 初始进入 前三个球设置小点，好排版
        // items[0].rank = this.mediumMax - 3
        // items[1].rank = this.mediumMax - 1
        // items[2].rank = 1

        // 要在第一个大球前面加多几个小球，将大球位置顶到偏中间去
        let _mediumList = items.slice(items.length-3)
        _mediumList = _mediumList.concat(items)
        // 数据拉长点，太短dymacticScroll会有问题
        _mediumList = _mediumList.concat(items).concat(items)
        this.mediumList = _mediumList.map((item) =>
          Object.assign(item, {
            id: this.getOnlyId(item.account.id, item.platform.id),
            key: this.mediumKey++,
          })
        )
        // console.log(
        //   "平台账号列表: ",
        //   items,
        //   items.map((i) => i.id)
        // )
        this.detailData = this.mediumList[this.curDetailIndex]

        this.$nextTick(async () => {
          this.getArticle(true)
          await utils.sleep(20)
          this.initStyle()
        })
      }
    },
    // 获取底部文章数据
    async getArticle(isInit = true) {

      if (isInit) {
        this.pageParam.page = 1
      } else {
        if (this.pageParam.page >= this.pageData.pageCount) {
          // 到底了，重新请求第一页
          this.pageParam.page = 1
        } else {
          this.pageParam.page++
        }
      }

      // console.log("请求文章数据：", { accountId, platformId })
      let { items, page } = await this.$api.content.getArticlePage({
        ...this.pageParam,
      })

      items = items.map((item) => Object.assign(item, { key: this.articleKey++ }))
      this.list = this.list.concat(items)

      this.pageData = page

      const _showPage = async () => {
        this.$emit('show', true)
        await utils.sleep(200)
        this.showPage = true

        // await utils.sleep(200);
        this.$refs.scrollList.startScroll()
        let { total, today, info } = this.statData

        this.$refs.pageRef.setData(
          [
            {
              title: "生产统计（篇）",
              children: [
                {
                  title: '今日更新',
                  value: today,
                  // percent: utils.floatMul(trend, 100),
                },
                {
                  title: '累计生产',
                  value: total
                }
              ]
            },
            {
              title: "时代财经App（篇）",
              children: [
                {
                  title: '今日更新',
                  value: info.tfcaijing.today,
                },
                {
                  title: '累计生产',
                  value: info.tfcaijing.total,
                }
              ]
            },
            {
              title: "时代周报App（篇）",
              children: [
                {
                  title: '今日更新',
                  value: info.epaper.today,
                },
                {
                  title: '累计生产',
                  value: info.epaper.total,
                }
              ]
            },
            {
              title: "新周刊App（篇）",
              children: [
                {
                  title: '今日更新',
                  value: info.neweekly.today,
                },
                {
                  title: '累计生产',
                  value: info.neweekly.total,
                }
              ]
            },
          ]
        )
      }

      this.firstInitFinish = true
      this.$nextTick(async () => {
        if (isInit) {
          let _timer = setInterval(() => {
            if (this.ballLoaded) {
              _showPage()
              clearInterval(_timer)
              _timer = null
            }
          }, 500)
        }
      })
    },
    async ballGifLoaded() {
      console.log('ball loaded')
      this.ballLoaded = true
    },
    // 初始化样式
    async initStyle() {
      this.tagWidth = document.querySelector('.detail').getBoundingClientRect().width

      // 以第四个球开始
      const startIndex = 3
      const id = this.mediumList[startIndex].id
      const _list = document.querySelectorAll(`[data-id='${id}']`) // 由于无限滚动，所以可能存在同个球出现多次的情况

      // 获取第一球dom
      let firstItem, firstIndex
      // const firstItem = document.querySelector(`[data-id='${id}']`)
      _list.forEach(i => {
        const index = i.parentElement.dataset.index - 0
        if (index != -1 &&
          (firstIndex === undefined || index < firstIndex)
        ) {
          firstIndex = index
          firstItem = i
        }
      })

      const firstItemRect = firstItem.getBoundingClientRect()
      const firstItemWidth = firstItemRect.width // parseFloat(this.ballStyle(this.mediumList[3]).width)
      const firstItemLeft = firstItemRect.left
      let left = firstItemLeft - this.tagWidth / 2 + firstItemWidth / 2
      this.startLeft = left
      // 设置球详细数据标签位置left
      this.detailStyle = {
        left: left + "px",
      }
      // console.log({left, firstItemLeft, firstItem, firstIndex, id, _list})

      // 设置中间动态球样式
      const animateBallLeft = firstItemLeft + firstItemWidth/2
      const ratio1 = parseInt(this.ballStyle(this.mediumList[3]).width) / 300
      const ratio2 = parseInt(this.ballStyle(this.mediumList[4]).width) / 300
      const leftPect = 50

      this.animateBall1Style = {
        transform:`translateX(-${leftPect}%) scale(${ratio1})`,
        left: animateBallLeft + 'px'
      }
      this.animateBall2Style = {
        transform:`translateX(-${leftPect}%) scale(${ratio2})`,
        left: animateBallLeft + 'px'
      }
      this.shadowStyle = {
        transform:`translateX(-${leftPect}%) scale(${ratio1})`,
        left: animateBallLeft + 'px'
      }

      this.initAccountBallAnimation()
    },
    // 设置每个粉色球的样式，通过排名来计算大小
    ballStyle(data, index) {
      const max = 280 //300
      const min = 80 // 50
      // const max = 337
      // const min = 56
      const range = max - min // 变化区间
      const total = this.mediumMax

      const widthStep = range / (total - 1)
      const width = max - Math.floor(widthStep * (data.rank - 1))

      // const heightStep = (300 - 50) / (total - 1)
      // const height = 300 - Math.floor(heightStep * (data.rank - 1))

      const style = {
        width: width + "px",
        height: width + "px",
      }

      if (this.isScale) style.transform = 'scaleX(0.88)'

      return style
    },
    // 中间账号滚动到结尾，加载下一页
    mediumScrollEnd() {
      // 更新最新数据，不影响下面赋值，不用阻塞，防止因为接口出问题或者速度慢导致球滚不动
      this.$api.content.getAccountRank()
      .then(res => {
        let { items, rank } = res
        if (items.length === 0) return
  
        this.mediumOriginList = items.map((item) =>
          Object.assign(item, {
            id: this.getOnlyId(item.account.id, item.platform.id),
          })
        )
        this.mediumMax = rank.max
        this.mediumMin = rank.min
      })

      let nextList = this.mediumOriginList.map((item) =>
        Object.assign(item, { key: this.mediumKey++ })
      )
      this.mediumList = this.mediumList.concat(nextList)
    },
    // 中间球列表动画
    initAccountBallAnimation() {
      const ifLoadNext = () => {
        const total = this.mediumMax
        if (this.curAccountIndex % total == 3) {
          console.log('ifLoadNext')
          this.mediumScrollEnd()
        }
      }
      const ballRun = () => {
        this.curAccountIndex++  // 下个球

        const mediumId = this.mediumList[this.curAccountIndex].id  // 对应的内容源id
        // console.log('mediumId', this.mediumList[this.curAccountIndex], this.mediumList, this.curAccountIndex)
        const mediumParentList = document.querySelectorAll(
          ".medium-scroll-list .vue-recycle-scroller__item-view"
        )
        const curMediumParentList = Array.from(mediumParentList).filter(
          (i) => i.querySelector(".medium-item").getAttribute("data-id") == mediumId
        )
        const curMediumParent = curMediumParentList[curMediumParentList.length - 1]
        let curMedium = curMediumParent.querySelector(".medium-item") // 当前内容源
        // console.log('当前文章', data)
        // console.log('当前内容源', curMedium, '当前内容源id', mediumId)
        
        const rect = curMedium.getBoundingClientRect()
        const resultLeft = rect.left + rect.width / 2 - (this.startLeft + this.tagWidth / 2)
        
        if (resultLeft > 0) {
          const detailData = curMedium.getAttribute("data-info")
          // console.log(detailData)
          this.detailData = JSON.parse(detailData)
          this.setAnimateBallStyle(curMedium.querySelector(".ball"))
          this.$refs.mediumScroll.scrollLeft(resultLeft, 30)
          // console.log({resultLeft})
          setTimeout(() => {
            ifLoadNext()
          }, 1500)
        }
      }
      this.accountTimer = setInterval(ballRun, this.accountBallTimes*5000)
    },

    // 底部文章滚动结尾，加载下一页
    articleScrollEnd() {
      if (!this.firstInitFinish) return // 初次加载数据触发的不请求下一页
      this.getArticle(false)
    },
    setAnimateBallStyle(mediumBallEl) {
      const max = this.isScale? 300*0.88:300
      const rect = mediumBallEl.getBoundingClientRect()
      const ratio = rect.width / max // 缩小倍数
      this.mediumAnimate = true

      // 球1，渐隐后消失后，大小设置为新球尺寸
      this.animateBall2Style.transform = `translateX(-50%) scale(${ratio},${ratio})`
      this.shadowStyle.transform = `translateX(-50%) scale(${ratio},${ratio})`
      this.animateBall1Style.transform = `translateX(-50%) scale(${ratio},${ratio})`
      
      setTimeout(() => {
        this.mediumAnimate = false
      }, 1500)
    },
    isToday(timestamp) {
      const today = new Date().setHours(0, 0, 0, 0)
      const date = new Date(timestamp * 1000).setHours(0, 0, 0, 0)
      return today == date
    },
    dateFormat(timestamp) {
      return utils.dateFormat(new Date(timestamp * 1000), "mm-dd HH:MM")
    },
    // 获取唯一id
    getOnlyId(accountId, platformId) {
      return `${accountId}_${platformId}`
    },
    // 从唯一id获取其他id
    getIdFromOnlyId(id) {
      const arr = id.split("_")
      return {
        accountId: Number(arr[0]),
        platformId: Number(arr[1]),
      }
    },
  },
  beforeDestroy() {
    console.log('beforeDestroy')
    this.mediumList = []
    this.list = []
    clearInterval(this.accountTimer)
    this.accountTimer = null
  },
}
</script>

<style lang="scss" scoped>
.article-page {
  background: url("~@/assets/images/bg.png") no-repeat;
  background-size: cover;
  background-position: left center;
  // transform: scale(0.88, 1);
  // transform-origin: left center;
}
.medium {
  position: absolute;
  bottom: 287px; //307px;
  // height: 510px;
  // bottom: 400px;
  left: 0;
  width: 100vw;
  height: 600px;

  &-item {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100%;
    flex-shrink: 0;
    // padding: 0 22px;
    padding: 0 25px;
    transition: 1s ease-in-out;
    
    &.current {
      // background: #0f0;
      opacity: 0;
    }

    &-content {
      position: relative;
      z-index: 10;
    }
    .ball {
      // width: 300px;
      // height: 300px;
      width: 100%;
      margin-bottom: 26px;
      // padding-top: 100%;
      border-radius: 50%;
      background: linear-gradient( 219deg, #E48BDE 0%, #F97491 100%);;
      // opacity: 0.8;
      transform-origin: bottom;
      box-shadow: 0px 30px 30px 0px rgba(246, 120, 158, 0.15);
    }
    .text {
      position: absolute;
      top: 568px;
      // top: 580px;
      left: 5px;
      right: 5px;
      // margin: 0 -5px;
      // width: 100%;
      font-size: 16px;
      @include font-oppo-m;
      font-weight: 500;
      color: $font-color;
      text-align: center;
      word-break: break-all;
    }
  }

  .ball-animate,
  .ball-animate-2 {
    // opacity: 0;
    position: absolute;
    left: 0;
    bottom: 105px; //105px;
    // bottom: 25px;
    // width: 382px;
    width: 340px; //340px;
    height: 340px; //340px;
    // background: url("~@/assets/images/content/ball_378.gif") no-repeat;
    // background-size: 340px 340px;
    // background-position: center;
    transform-origin: center 330px;
    transition: 1.3s ease;
    z-index: 10;
  }
  .ball-animate-inner {
    width: 340px; //340px;
    height: 340px; // 340px;
    background: url("~@/assets/images/content/ball_378.gif") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    transform-origin: center 330px;
  }

  .ball-shadow {
    position: absolute;
    bottom: 5px;
    width: 280px;
    height: 215px;
    background: linear-gradient(180deg, #7546e4 0%, rgba(238, 241, 250, 0.5) 100%);
    border-radius: 0px 0px 0px 0px;
    opacity: 0.5;
    filter: blur(30px);
    z-index: 0;
    transition: transform 0.2s 0.2s ease;
  }

  .ball-animate-2 {
    opacity: 0;
    z-index: 9;
    // background: #f00;
    // border-radius: 50%;
  }
  .detail {
    // opacity: 0;
    position: absolute;
    left: 0;
    bottom: -90px;
    width: 244px;
    height: 268px;
    padding: 17px 32px;
    border-radius: 12px;
    background: url("~@/assets/images/content/product_card_hover@2x.png") no-repeat;
    background-size: cover;
    // backdrop-filter: blur(35px);
    overflow: hidden;
    z-index: 10;
    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: #fff;
    //   opacity: .66;
    //   overflow: hidden;
    //   filter: blur(35px);
    // }
    .detail-title {
      padding: 12px 18px;
      height: 42px;
      // background: linear-gradient( 225deg, #A145D6 0%, #3847F8 100%);
      // opacity: 0.26;
      color: #fff;
      font-size: 14px;
      @include font-oppo-b;
    }
    .detail-content {
      padding: 15px;
      // background-color: rgba(255, 255, 255, 0.66);
      .detail-logo {
        position: absolute;
        top: 78px;
        right: 50px;
        width: 36px;
        height: 36px;
        border-radius: 7px;
        transition: 0.3s;
      }
      .detail-text {
        color: $font-color;
        font-size: 13px;
        transition: 0.3s;
      }
      .detail-num {
        margin-bottom: 19px;
        color: $font-color-high;
        height: 44px;
        line-height: 44px;
        font-size: 36px;
        font-weight: bold;
        @include font-din;
        transition: 0.3s;
      }
      .detail-bottom {
        display: flex;
        &-item {
          flex-basis: 50%;
          .detail-value {
            margin-top: 4px;
            font-size: 16px;
            line-height: 19px;
            font-weight: bold;
            @include font-din;
            color: $font-color;
            transition: 0.3s;
          }
        }
      }
    }
  }
}
.article {
  position: absolute;
  left: 0;
  right: -20vw;
  // bottom: 45px;
  // height: 124px;
  bottom: 0px;
  // height: 169px;
  height: 198px;

  &.scale {
    transform: scaleX(0.88);
    transform-origin: left;
  }

  &-item {
    // margin-right: 14px;
    // flex: 0 0 272px;
    // height: 124px;
    margin-right: 16px;
    flex: 0 0 304px;
    height: 138px;
    padding: 12px 22px 16px;
    border-radius: 11px;
    background-color: #ede4fd;
    box-shadow: 0 18px 32px 0 rgba($color: #d6d1e8, $alpha: 0.55);
    &.new {
      background-color: $font-color;
      .article-item-time,
      .article-item-content {
        color: #fff;
      }
      .article-item-bottom {
        color: rgba(255, 255, 255, 0.7);
      }
    }
    &-time {
      margin-bottom: 10px;
      // font-size: 18px;
      // line-height: 21px;
      font-size: 20px;
      line-height: 23px;
      @include font-din;
      font-weight: bold;
      color: #a245d4;
      &.today {
        color: $font-color-high;
      }
    }
    &-content {
      margin-bottom: 10px;
      color: $font-color;
      font-weight: 500;
      @include font-oppo-m;
      // height: 40px;
      // line-height: 19px;
      height: 48px;
      font-size: 18px;
      line-height: 24px;
      @include textOverflow;
    }
    &-bottom {
      font-size: 12px;
      font-weight: 400;
      color: #1c104c;
      line-height: 13px;
      font-family: OPPOSans-Regular, OPPOSans;
      @include textOverflow($line: 1);
      .logo {
        position: relative;
        top: 3px;
        margin-right: 6px;
        width: 14px;
        height: 14px;
        border-radius: 2px;
        object-fit: contain;
        // border: 1px solid #3C3C43;
      }
    }
  }
}

.animate-detail {
  animation: 1s linear detail;
}
.animate-ball-1 {
  animation: 1s linear hidden forwards;
}
.animate-ball-2 {
  animation: 0.3s linear 0.2s visible forwards;
}
.animate-bg-ball {
  // animation: 1.5s ease-out trans;
}
</style>

<style lang="scss">
.scroll-list {
  .vue-recycle-scroller__item-wrapper {
    // margin-left: 660px;
    margin-left: 0;
  }
}
.medium-scroll-list {
  &.ready {
    .vue-recycle-scroller__item-view {
      top: -73px;
    }
  }
}

.animate-ball-bg-enter {
  animation: 1s linear hidden forwards;
}
.animate-ball-bg-leave {
  animation: 1s linear visible forwards;
}

@keyframes hidden {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes detail {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes trans {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
