<template>
  <div class="line-chart-2">
    <div class="chart-title" :class="{ show: showLine }">{{title}}经营曲线（万元）</div>
    <div class="chart-wrap" :id="chartId"></div>
    <div class="chart-point">
      <div class="point" v-for="(item, index) in pointPosition" :class="item.cls" :key="item.id" :style="pointStyle(index, item)"></div>
    </div>
    <div class="chart-line" v-if="linePosition.length" :class="{ show: showLine }">
      <div v-for="(item, index) in list" :key="index">
        <div class="line-item" :class="{show: index !== currentIndex}" :style="lineStyle(index, item)">
          <!-- <div class="today" v-if="index===list.length-1">
            <div class="today">
              <div class="today-title">今日预估</div>
              <div class="today-data">{{ (item.contractAmount/10000)| numTrans(true) }}万元</div>
            </div> -
          </div> -->
          <div class="item-label">
            <div class="color1">{{ utils.transToTenthousand(item.contractAmount) | numTrans(true) }}</div>
            <div class="color2">{{ utils.transToTenthousand(item.contractPublishAmount) | numTrans(true) }}</div>
            <div class="color3">{{ utils.transToTenthousand(item.contractPaymentAmount) | numTrans(true) }}</div>
          </div>
        </div>
      </div>
      <div class="line-item show" :style="lineStyle(currentIndex, list[currentIndex])">
        <div class="today">
          <div class="today-item">
            <div class="today-item-title">{{ getDate(list[currentIndex].contractDate) }}营收</div>
            <div class="today-item-data">{{ utils.transToTenthousand(list[currentIndex].contractAmount) | numTrans(true) }}万元</div>
          </div>
          <div class="today-item">
            <div class="today-item-title">{{ getDate(list[currentIndex].contractDate) }}登版</div>
            <div class="today-item-data">{{ utils.transToTenthousand(list[currentIndex].contractPublishAmount) | numTrans(true) }}万元</div>
          </div>
          <div class="today-item">
            <div class="today-item-title">{{ getDate(list[currentIndex].contractDate) }}回款</div>
            <div class="today-item-data">{{ utils.transToTenthousand(list[currentIndex].contractPaymentAmount) | numTrans(true) }}万元</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/assets/javascripts/utils'

export default {
  name: "regionLineChart",
  data() {
    return {
      chartId: "",
      $chart: null,
      list: [],
      linePosition: [],
      pointPosition: [],
      showLine: false,
      currentIndex: 0,
      timer: null,
      title: '',
      utils
    }
  },
  methods: {
    getDate(data) {
      let date = new Date(data)
      if (date.setHours(0,0,0,0) == new Date().setHours(0,0,0,0)) {
        return '今日'
      }
      return ''//utils.dateFormat(date, 'mm-dd')
    },
    getRange(list) {
      const max = list.reduce((prev, cur) => Math.max(prev, cur), 0)
      const min = list.reduce((prev, cur) => prev? Math.min(prev, cur):cur, null)
      return { max, min }
    },
    getMaxOrMin(type, [a, b, c]) {
      let data = a;
      let index = 0;
      if (type == 'min') {
        if (b < data) {
          data = b;
          index = 1;
        }
        if (c < data) {
          data = c;
          index = 2;
        }
      } else if (type == 'max') {
        if (b > data) {
          data = b;
          index = 1;
        }
        if (c > data) {
          data = c;
          index = 2;
        }
      }
      return { data, index };
    },
    initChart(chartId, list, title) {
      this.title = title
      this.list = list
      const labels = list.map(i => utils.dateFormat(new Date(i.contractDate), 'mm-dd'))
      let list1 = list.map(i => utils.transToTenthousand(i.contractAmount))  // 预计总营收
      let list2 = list.map(i => utils.transToTenthousand(i.contractPublishAmount)) // 预计总登版
      let list3 = list.map(i => utils.transToTenthousand(i.contractPaymentAmount)) // 预计总回款

      const { max: max1, min: min1 } = this.getRange(list1)
      const { max: max2, min: min2 } = this.getRange(list2)
      const { max: max3, min: min3 } = this.getRange(list3)
      // console.log({list1,list2,list3})
      // 取三条数值的最高值，乘1.4为了让折线平滑时不会顶到最高点挡住标题
      const yAxisMax = this.getMaxOrMin('max', [max1, max2, max3]).data * 1.4
      // console.log({yAxisMax1, yAxisMax2})
      const option = {
        grid: {
          top: '5%',
          left: "4%",
          right: "5%",
          bottom: '5%',
          containLabel: false,
        },
        xAxis: [
          {
            type: "category",
            axisTick: false,
            axisLine: {
              show: false,
            },
            axisLabel: {
              // padding: [100, 0,0,0],
              textStyle: {
                color: "#1C104C",
                fontWeight: 500,
                fontSize: 12,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                width: 1.2,
                color: "rgba(0,0,0,.15)",
              },
            },
            boundaryGap: false, //[1, 1],
            data: labels,
          },
        ],
        yAxis: [
          {
            show: false,
            type: "value",
            max: yAxisMax,
            // min: yAxisMin1,
            splitLine: {
              show: false,
            },
            axisLabel: {
              padding: [0, 0, 0, 0],
              textStyle: {
                color: "#A3A09E",
                fontWeight: 500,
              },
            },
          },
          // {
          //   show: false,
          //   type: "value",
          //   max: yAxisMax2,
          //   // min: yAxisMin2,
          //   splitLine: {
          //     show: false,
          //   },
          // },
          // {
          //   show: false,
          //   type: "value",
          //   max: yAxisMax3,
          //   // min: yAxisMin2,
          //   splitLine: {
          //     show: false,
          //   },
          // },
        ],
        series: [
          {
            name: "contractAmount",
            type: "line",
            symbol: 'none',
            yAxisIndex: 0,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#574AF2",
                },
                {
                  offset: 0.7,
                  color: "rgba(255,255,255,0)",
                },
                {
                  offset: 1,
                  color: "rgba(255,255,255,0)",
                },
              ]),
              // color: '#f00'
            },
            label: {
              show: false,
            },
            lineStyle: {
              color: "#574AF2",
              width: 2,
            },
            itemStyle: {
              normal: {
                color: "#A245D4",
              },
            },
            smooth: 0.3,
            data: list1,
          },
          {
            name: "contractNum",
            type: "line",
            symbol: 'none',
            // yAxisIndex: 1,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(250, 148, 246, 0.50)",
                },
                // {
                //   offset: 0.5,
                //   color: "rgba(248,118,151,0)",
                // },
                {
                  offset: 1,
                  color: "rgba(238, 116, 249, 0)",
                },
              ]),
            },
            label: {
              show: false,
            },
            lineStyle: {
              // color: "#E955EC",
              width: 2,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#9E49F4' // 0% 处的颜色
                }, {
                    offset: 1, color: '#EC56CB' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            itemStyle: {
              normal: {
                color: "#F17EAF",
              },
            },
            smooth: 0.3,
            data: list2,
          },
          {
            name: "contractNum",
            type: "line",
            symbol: 'none',
            // yAxisIndex: 2,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(250, 148, 246, 0.50)",
                },
                {
                  offset: 1,
                  color: "rgba(238, 116, 249, 0)",
                },
              ]),
            },
            label: {
              show: false,
            },
            lineStyle: {
              // color: "#E955EC",
              width: 2,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#9E49F4' // 0% 处的颜色
                }, {
                    offset: 1, color: '#EC56CB' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            itemStyle: {
              normal: {
                color: "#F970CA",
              },
            },
            smooth: 0.3,
            data: list3,
          },
        ],
      }

      this.chartId = chartId
      this.$nextTick(() => {
        if (!this.$chart) {
          this.$chart = this.$echarts.init(document.getElementById(this.chartId))
        }
        this.$chart.setOption(option)
        this.initLabel()
        this.initPointPosition()
      })
    },
    async initLabel() {
      let model1 = this.$chart.getModel().getSeriesByIndex(0).getData()._layout;
      let model2 = this.$chart.getModel().getSeriesByIndex(1).getData()._layout;
      let model3 = this.$chart.getModel().getSeriesByIndex(2).getData()._layout;
      let points = model1.points
      
      this.linePosition = []
      
      points.forEach((point1, index) => {
        if (index % 2 == 0) {
          this.linePosition.push({ x: point1-10.5 })
        } else {
          const point2 = model2.points[index]
          const point3 = model3.points[index]
          
          const { data: yMax } = this.getMaxOrMin('max', [point1, point2, point3])
          const { data: yMin } = this.getMaxOrMin('min', [point1, point2, point3])
          const height = 100 // yMax-yMin+100
          this.linePosition[this.linePosition.length - 1].y = yMin - 60 //yMax-height+40
          this.linePosition[this.linePosition.length - 1].height = height - 2
        }
      })
      await utils.sleep(1000)
      this.showLine = true
      this.lineAnimate()
    },
    async initPointPosition() {
      let model1 = this.$chart.getModel().getSeriesByIndex(0).getData()._layout;
      let model2 = this.$chart.getModel().getSeriesByIndex(1).getData()._layout;
      let model3 = this.$chart.getModel().getSeriesByIndex(2).getData()._layout;
      let points = model1.points

  
      // console.log({ model1: model1.points, model2: model2.points, model3: model3.points })
      this.pointPosition = []
      points.forEach((point1, index) => {
        if (index % 2 == 0) {
          // 奇数项为x坐标，偶数项为y坐标
          this.pointPosition.push({ x: point1-10.5, id: Math.random()*Date.now() })
        } else {
          // y坐标显示的点取三条线中最大的那个值
          const point2 = model2.points[index]
          const point3 = model3.points[index]
          
          const { data: curPoint, index: pointIndex } = this.getMaxOrMin('min', [point1, point2, point3])
          this.pointPosition[this.pointPosition.length - 1].y = curPoint
          this.pointPosition[this.pointPosition.length - 1].cls = `color${pointIndex+1}`
        }
      })
    },
    lineStyle(index) {
      try {
        const { x, y, height } = this.linePosition[index]
        return {
          top: y + 'px',
          left: x + 'px',
          height: height + 'px'
        }
      } catch(err) {
        return {}
      }
    },
    pointStyle(index, data) {
      const { x, y } = this.linePosition[index]
      let top = y + 92
      let left = x - 5

      return {
        top: top + "px",
        left: left + "px",
      }
    },
    lineAnimate() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.currentIndex == this.list.length - 1) {
          this.$emit('next')
          this.currentIndex = 0
          clearInterval(this.timer)
        } else {
          this.currentIndex++
        }
      }, 4000)
    }
  },
  destroyed() {
    this.$chart.dispose()
    this.$chart = null
    this.chartId = null
    this.markAreaVal = 0
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>

<style lang="scss" scoped>
.line-chart-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  .chart-title {
    margin-bottom: 20px;
    font-size: 14px;
    @include font-oppo-m;
    color: #1c104c;
    line-height: 18px;
    opacity: 0;
    transition: .3s ease;
    &.show {
      opacity: 1;
    }
  }
  .chart-wrap {
    position: relative;
    left: -10px;
    height: calc(100% - 38px);
  }
  .chart-point {
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0px;
    z-index: 10;
    .point {
      position: absolute;
      top: 0;
      left: 0;
      width: 12px;
      height: 12px;
      // background: linear-gradient(219deg, rgba(228, 139, 222, 1), rgba(249, 116, 145, 1));
      border-radius: 50%;
      z-index: 10;
      transition: .3s ease;
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 2px;
        left: 2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fff;
      }
      &.color1 {
        background-color: #574AF2;
      }
      &.color2 {
        background-color: #A245D4;
      }
      &.color3 {
        background-color: #F970CA;
      }
    }
  }

  .chart-line {
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0px;
    transition: .5s ease;
    opacity: 0;
    &.show {
      opacity: 1;
    }
    .line-item {
      position: absolute;
      width: 1px;
      border: 1px dashed #A245D4;
      transition: .3s ease;
      opacity: 0;
      &.show {
        opacity: 1;
      }
      .item-label {
        position: absolute;
        top: -60px;
        left: 0;
        transform: translateX(-50%);
        display: block;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        &>div {
          @include font-din;
        }
      }

      .today {
        position: absolute;
        top: -130px;
        left: 0;
        transform: translateX(-50%);
        z-index: 10;
        
        &-item {
          margin-bottom: 15px;
          min-width: 94px;
          padding: 6px 10px;
          background: linear-gradient( 221deg, #A145D6 0%, #3847F8 100%);
          border-radius: 8px;
          font-size: 18px;
          text-align: center;
          color: #fff;
          font-weight: bold;
          white-space: nowrap;
          &:nth-child(2) {
            background: linear-gradient( 90deg, #E48BDE 0%, #F97491 100%);
          }
          &:nth-child(3) {
            background: linear-gradient( 207deg, #FF7635 2%, #8831E2 100%);
          }
  
          &-title {
            margin-bottom: 4px;
            font-size: 16px;
          }
          
          &-data {
            @include font-din;
            line-height: 21px;
          }
        }
      }
    }

    .color1 {
      color: #574AF2;
    }
    .color2 {
      color: #A245D4;
    }
    .color3 {
      color: #F970CA;
    }
  }

}
</style>
