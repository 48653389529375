<template>
  <Page class="plate-page" ref="pageRef" :show="showPage" :isScale="isScale">
    <div class="plate-chart" :class="{ scale: isScale }">
      <PlateLineChart ref="lineChartRef"></PlateLineChart>
    </div>
    <div class="plate" :class="{ scale: isScale }">
      <div class="plate-title"><img src="@/assets/images/contract/icon_plate_jingying@2x.png" />全年区域&板块概况</div>
      <scroll-list
        :list="list"
        :item-size="466"
        :buffer="300"
        :prerender="20"
        :speed="bottomScrollSpeed"
        ref="scrollList"
        class="plate-scroll-list"
        @scrollEnd="articleScrollEnd"
      >
        <template v-slot="{ item, index }">
          <div
            class="plate-item"
          >
            <div class="plate-item-info">
              <!-- <div class="item-left">
                <div class="item-title">合同量</div>
                <div class="item-data">
                  <div class="item-data-num">{{ item.contractNum }}</div>
                  <div class="item-data-sub" v-if="item.contractRiseNum!=0" :class="{up: item.contractRiseNum>0, down: item.contractRiseNum<0}">{{ item.contractRiseNum }}</div>
                </div>
              </div> -->
              <div class="info-item">
                <div class="item-title">预计营收(万元)</div>
                <div class="item-data">
                  <!-- <div class="item-data-num">{{ item.contractAmount | numTrans(true) }}</div> -->
                  <div class="item-data-num">{{ item.contractAmount }}</div>
                  <div class="item-data-sub" v-if="item.contractRiseAmount!=0" :class="{up: item.contractRiseAmount>0, down: item.contractRiseAmount<0}">{{ Math.abs(item.contractRiseAmount) | numTrans }}</div>
                </div>
              </div>
              <div class="info-item">
                <div class="item-title">预计登版(万元)</div>
                <div class="item-data">
                  <!-- <div class="item-data-num">{{ item.contractAmount | numTrans(true) }}</div> -->
                  <div class="item-data-num">{{ item.contractPublishAmount }}</div>
                  <div class="item-data-sub" v-if="item.contractRisePublishAmount!=0" :class="{up: item.contractRisePublishAmount>0, down: item.contractRisePublishAmount<0}">{{ Math.abs(item.contractRisePublishAmount) | numTrans }}</div>
                </div>
              </div>
              <div class="info-item">
                <div class="item-title">预计回款(万元)</div>
                <div class="item-data">
                  <!-- <div class="item-data-num">{{ item.contractAmount | numTrans(true) }}</div> -->
                  <div class="item-data-num">{{ item.contractPaymentAmount }}</div>
                  <div class="item-data-sub" v-if="item.contractRisePaymentAmount!=0" :class="{up: item.contractRisePaymentAmount>0, down: item.contractRisePaymentAmount<0}">{{ Math.abs(item.contractRisePaymentAmount) | numTrans }}</div>
                </div>
              </div>
            </div>
            <div class="plate-item-bottom">
              <img :src="item.resourceIcon" class="logo" />{{ item.resourceName }}
            </div>
          </div>
        </template>
      </scroll-list>
    </div>
  </Page>
</template>

<script>
import Page from "./components/page"
import ScrollList from "@/components/scrollList.vue"
import PlateLineChart from "./components/plateLineChart.vue"
import utils from "@/assets/javascripts/utils"
import _ from 'lodash'

export default {
  components: { Page, ScrollList, PlateLineChart },
  props: {
    isScale: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPage: false,
      utils,
      list: [],
      articleKey: 0,
      bottomScrollSpeed: 0.5, // 底部滚动的速度
      firstInitFinish: false, // 初次加载底部数据是否结束
      fetchTimer: null,
      lastFetchTime: Date.now(), // 上次更新时间
    }
  },
  created() {
    this.$emit('loading')
  },
  mounted() {
    this.$emit('show')
    this.initPage()
    this.autoRefreshData()
  },
  methods: {
    async initPage() {
      this.getModuleData(true)
      this.getData()
    },
    // 合同数据
    async getData(isRefresh = false) {
      const res = await this.$api.contract.getIndexData()
      this.$nextTick(async () => {
        await utils.sleep(1200)
        this.$refs.pageRef.setData(1, [
          // {
          //   title: new Date().getFullYear() +  "年成交合同",
          //   value: contractNum,
          //   percent: contractRiseNum,
          // },
          // {
          //   title: "广告合同",
          //   value: contractGGNum,
          //   percent: contractRiseGGNum,
          // },
          // {
          //   title: "其他合同",
          //   value: contractOtherNum,
          //   percent: contractRiseOtherNum,
          // },
            {
              title: "预估总营收（万元）",
              value: utils.transToTenthousand(res.contractAmount),
              percent: utils.transToTenthousand(res.contractRiseAmount), 
              // percent: 499,  // remark: 给UI验收，后面需要换回去 
            },
            {
              title: "预估总登版（万元）",
              value: utils.transToTenthousand(res.contractPublishAmount),
              percent: utils.transToTenthousand(res.contractRisePublishAmount),
              // percent: -99,  // remark: 给UI验收，后面需要换回去 
            },
            {
              title: "预估总回款（万元）",
              value: utils.transToTenthousand(res.contractPaymentAmount), 
              percent: utils.transToTenthousand(res.contractRisePaymentAmount), 
              // percent: 2203,  // remark: 给UI验收，后面需要换回去 
            },
        ])   
        let amounts = res.amounts.map(i => {
          let date = new Date(i.contractDate).setHours(0, 0, 0, 0)
          return {
            ...i,
            contractDate: date
          }
        })
        this.setLineData(amounts, isRefresh)
      })
    },
    setLineData(data, isRefresh) {
      // console.log('setLineData', data)
      if (isRefresh) {
        this.$refs.lineChartRef.updateData(data)
        return 
      }
      this.$refs.lineChartRef.initChart('lineChartRef', data)
    },
    // 底部板块数据
    async getModuleData(init = false) {
      let { module } = await this.$api.contract.getModule()
      module = module.map((item) => {
        return Object.assign(item, { 
          key: this.articleKey++,
          contractAmount:  _.round(_.divide(item.contractAmount, 10000), 2),
          contractRiseAmount: _.round(_.divide(item.contractRiseAmount, 10000), 2), 
          // contractRiseAmount: -23000,  // remark: 给UI验收，后面需要换回去 
          contractPublishAmount: _.round(_.divide(item.contractPublishAmount, 10000), 2),
          contractRisePublishAmount: _.round(_.divide(item.contractRisePublishAmount, 10000), 2), 
          // contractRisePublishAmount: -500,  // remark: 给UI验收，后面需要换回去 
          contractPaymentAmount: _.round(_.divide(item.contractPaymentAmount, 10000), 2),
          contractRisePaymentAmount: _.round(_.divide(item.contractRisePaymentAmount, 10000), 2),
          // contractRisePaymentAmount: 3000,  // remark: 给UI验收，后面需要换回去 
        })
      })
      
      this.list = this.list.concat(module)

      if (init) {
        if (this.list.length < 15) {
          // 数量不够，次数来凑
          this.getModuleData(true)
        } else {
          this.firstInitFinish = true
          this.$nextTick(async () => {
            if (init) {
              this.$emit('show', true)
              await utils.sleep(200)
              this.showPage = true
              await utils.sleep(500)
              this.$refs.scrollList.startScroll()
            }
          })
        }
      }
    },
    // 底部文章滚动结尾，加载下一页
    articleScrollEnd() {
      if (!this.firstInitFinish) return // 初次加载数据触发的不请求下一页
      this.getModuleData()
    },
    dateFormat(timestamp) {
      if (this.isToday(timestamp)) {
        return utils.dateFormat(new Date(timestamp * 1000), "HH:MM")
      } else {
        return utils.dateFormat(new Date(timestamp * 1000), "mm-dd HH:MM")
      }
    },
    // 轮询更新
    autoRefreshData() {
      this.fetchTimer = setInterval(() => {
        const curTime = Date.now()
        if (curTime - this.lastFetchTime > 1000 * 30) {
          this.getData(true)
          this.lastFetchTime = curTime
        }
      }, 5*1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.fetchTimer)
    this.list = []
    this.fetchTimer = null
  },
}
</script>

<style lang="scss" scoped>
.plate-page {
  background: url('~@/assets/images/bg.png') no-repeat;
  background-size: cover;
  background-position: left center;
  // transform: scale(0.88, 1);
  // transform-origin: left center;
}

.plate-chart {
  position: absolute;
  top: 110px;
  left: 449px;
  right: 50px;
  bottom: 287px; //277px;
}
.plate {
  position: absolute;
  left: 0;
  right: -20vw;
  bottom: 0px;
  height: 300px;
  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    padding-left: 50px;
    color: #1C104C;
    font-size: 20px;
    line-height: 23px;
    @include font-oppo-b;
    img {
      height: 27px;
      margin-right: 9px;
    }
  }

  &-item {
    margin-right: 16px;
    flex: 0 0 450px;
    height: 160px;
    padding: 24px 0px 21px;
    border-radius: 12px;
    background-color: #EDE4FD;
    box-shadow: 0px 18px 32px 0px rgba(140,128,122,0.08);
    &-info {
      display: flex;
      margin-bottom: 12px;
      font-weight: bold;
      color: $font-color;
      @include font-oppo-m;
      .info-item {
        position: relative;
        flex: 1;
        padding-left: 22px;
        &:not(:first-of-type) {
          &:after {
            content: '';
            position: absolute;
            top: 7px;
            left: 0px;
            height: 66px;
            width: 1px;
            background: #D7CBEB;
          }
        }
        .item-title {
          margin-bottom: 8px;
          font-size: 14px;
          line-height: 18px;
          color: #A245D4;
        }
        .item-data {
          // display: flex;
          // align-items: center;
          height: 50px;
          &-num {
            margin: 5px auto 2px;
            font-size: 25px;
            @include font-din;
            line-height: 31px;
            font-weight: bold;
          }
          &-sub {
            position: relative;
            padding-left: 16px;
            font-size: 14px;
            @include font-din;
            &.up {
              color: #E95004;
              &:before {
                position: absolute;
                bottom: 2px;
                left: 0;
                content: '';
                display: block;
                width: 12px;
                height: 9px;
                background: url('~@/assets/images/contract/icon_arrow_up@2x.png') no-repeat;
                background-size: contain;
              }
            }
            &.down {
              color: #219F67;
              &:before {
                position: absolute;
                bottom: 2px;
                left: 0;
                content: '';
                display: block;
                width: 12px;
                height: 9px;
                background: url('~@/assets/images/contract/icon_arrow_down@2x.png') no-repeat;
                background-size: contain;
              }
            }
          }
        }
      }
    }
    &-bottom {
      padding: 0 22px;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: #1C104C;
      @include font-oppo-m;
      @include textOverflow($line: 1);
      .logo {
        position: relative;
        top: 4px;
        margin-right: 8px;
        width: 22px;
        height: 22px;
        border-radius: 2px;
        // border: 1px solid #3C3C43;
      }
    }
  }
}
</style>

<style lang="scss">
.scroll-list {
  .vue-recycle-scroller__item-wrapper {
    // margin-left: 660px;
    margin-left: 0;
  }
}
.medium-scroll-list {
  &.ready {
    .vue-recycle-scroller__item-view {
      top: -73px;
    }
  }
}

.animate-ball-bg-enter {
  animation: 1s linear hidden forwards;
}
.animate-ball-bg-leave {
  animation: 1s linear visible forwards;
}

@keyframes hidden {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes detail {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes trans {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
