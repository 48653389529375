<template>
  <div class="content-list" v-loading="loading"  @click="clickHandle">
    <!-- <div class="content-list-shade"></div> -->
    <recycle-scroller
      class="scroll-list"
      key-field="key"
      :buffer="buffer"
      :prerender="prerender"
      :item-size="itemSize"
      :items="list"
      :direction="direction"
      @scroll-end="scrollEnd"
      ref="scrollList"
      style="width: 100%;height:100%"
    >
      <template v-slot="{ item, index }">
        <slot :item="item" :index="index"></slot>
      </template>
    </recycle-scroller>
  </div>
</template>

<script>
import Vue from "vue"
import utils from "@/assets/javascripts/utils"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"
import VueVirtualScroller from "vue-virtual-scroller"
Vue.use(VueVirtualScroller)

export default {
  name: "ScrollList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    direction: {
      type: String,
      default: 'horizontal'
    },
    itemSize: {
      type: Number,
      default: 1
    },
    buffer: {
      type: Number,
      default: 200
    },
    prerender: {
      type: Number,
      default: 0
    },
    speed: {
      type: Number,
      default: 0.5
    },
    scrollType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      utils,
      loading: false,

      dataKey: 0,
      pauseScroll: false,
      rid: null, // 动画实例id
    }
  },
  methods: {
    scrollEnd() {
      this.$emit('scrollEnd')
    },
    startScroll() {
      if (this.direction == 'vertical') {
        this.scrollType == 'step'? this.startScrollStepTop() : this.startScrollTop()
      } else {
        this.startScrollLeft()
      }
    },
    startScrollLeft() {
      const scrollListRef = this.$refs.scrollList.$el
      window.cancelAnimationFrame(this.rid)

      let scrollLeft = scrollListRef.scrollLeft
      const func = () => {
        if (this.pauseScroll) return
        scrollLeft = scrollLeft + this.speed
        scrollListRef.scrollLeft = scrollLeft
        this.rid = requestAnimationFrame(func)
      
        parseInt(scrollLeft % 100) == 0 && this.$emit('scroll', { left: scrollLeft })
      }
      this.rid = requestAnimationFrame(func)
    },
    startScrollTop() {
      const scrollListRef = this.$refs.scrollList.$el
      window.cancelAnimationFrame(this.rid)

      let scrollTop = scrollListRef.scrollTop
      const func = () => {
        if (this.pauseScroll) return
        scrollTop = scrollTop + this.speed
        scrollListRef.scrollTop = scrollTop
        this.rid = requestAnimationFrame(func)
        scrollTop % 100 == 0 && this.$emit('scroll', { top: scrollTop })
      }
      this.rid = requestAnimationFrame(func)
    },
    startScrollStepTop() {
      const scrollListRef = this.$refs.scrollList.$el

      const timeFunc = () => { 
        let scrollDis = 0 // 当前滚动的数值
        let lastScrollTop = scrollListRef.scrollTop
        let scrollTop = scrollListRef.scrollTop
        const func = () => {
          // if (this.pauseScroll) return
          if (scrollDis > this.itemSize+1) {
            // 滚动大于一条停止滚
            this.$emit('stepScroll')
            return 
          }
 
          scrollTop = scrollTop + this.speed
          scrollDis += this.speed
          if ((scrollTop - lastScrollTop) > this.itemSize) {
            scrollTop = lastScrollTop + this.itemSize
          }
     
          scrollListRef.scrollTop = scrollTop
          this.rid = requestAnimationFrame(func)
        }
        this.rid = requestAnimationFrame(func)

        setTimeout(() => {
          timeFunc()   
        }, 5000)
      }
      setTimeout(() => {
        timeFunc()   
      }, 5000)
    },
    clickHandle() {
      if (process.env.NODE_ENV !== 'development') return
      if (!this.pauseScroll) {
        this.pauseScroll = true
      } else {
        this.pauseScroll = false
        this.startScroll()
      }
    }
  },
  destroyed() {
    window.cancelAnimationFrame(this.rid)
    this.rid = null
  }
}
</script>

<style lang="scss">
.vue-recycle-scroller__item-wrapper {
  .vue-recycle-scroller__item-view {
    display: flex;
    // flex: 0 0 430px;
  }
}
</style>
<style lang="scss" scoped>
.content-list {
  height: 100%;
  overflow: hidden;
  position: relative;
  &-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 30;
  }
  .scroll-list {
    @include scrollBar($width: 0);
  }
}
</style>
